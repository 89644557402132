import React from 'react';

import HighlightBox from '@helsenorge/designsystem-react/components/HighlightPanel';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';

import { TopMessageProps } from './TopMessageComponent';
import { TopMessageVisibility } from '../../constants/topMessageVisibility';
import { ReplaceLinks } from '../navigator/replaceLinks';

export const RenderFormFillingInformation = ({
  resources,
  userMetadata,
  openSidebar,
  visibility,
}: TopMessageProps): React.JSX.Element | null => {
  if (visibility === TopMessageVisibility.Off || !isAuthorized()) return null;

  const cannotRepresentOthers =
    (userMetadata.CanOnlyBePerformedBySubject && !userMetadata.IsRepresentert) ||
    (!userMetadata.CanOnlyBePerformedBySubject && userMetadata.IsRepresentert);

  const messageKey = cannotRepresentOthers ? 'message_canNotRepresentOthers' : 'message_canRepresentOthers';
  const testId = cannotRepresentOthers ? 'top-message-is-representert' : 'top-message-not-is-representert';

  return (
    <HighlightBox size="fluid" color="blueberry" testId={testId}>
      <ReplaceLinks messageText={resources[messageKey]} resources={resources} openSidebar={openSidebar} />
    </HighlightBox>
  );
};
