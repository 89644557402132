import React from 'react';

import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import router from 'src/routes';

import { AppStore } from '../store/reducers';
import '../css/skjemautfyller.scss';

interface RootProps {
  store: AppStore;
}

export const Root = ({ store }: RootProps): React.JSX.Element => {
  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};
export default Root;
