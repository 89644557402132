import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getErrorMessageFromErrorCode } from 'src/store/selectors/errorCodesSelectors';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';

import { HNSkjemaErrorCodes, HNSkjemaFrontend } from '../../types/Resources';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

const SkjemaLoadError = (): React.JSX.Element => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const loadSkjemaoppgaveErrorMessage = useAppSelector(state => state.refero.ui.loadSkjemaoppgaveErrorMessage) as keyof HNSkjemaErrorCodes;
  const loadSkjemaErrorCode = useAppSelector(state => state.refero.ui.loadSkjemaoppgaveErrorCode) as keyof HNSkjemaFrontend | undefined;
  const errorMessage = useAppSelector(state => getErrorMessageFromErrorCode(state, loadSkjemaErrorCode));

  const description = errorMessage ?? loadSkjemaoppgaveErrorMessage;

  return (
    <section className="defaultspacing" data-testid="skjema-load-error">
      <NotificationPanel testId="notification-skjema-load-error" variant="error" label={resources?.loadSkjemaErrorTitle}>
        {description}
      </NotificationPanel>
    </section>
  );
};

export default SkjemaLoadError;
