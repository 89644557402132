import React from 'react';

import { HeaderFooterEvents } from '@helsenorge/framework-utils/web-component/constants';
import { HNaddEventListener } from '@helsenorge/framework-utils/web-component/events';

import { lagreSkjema } from '../store/actions/actionCreators';
import { useAppDispatch } from '../store/reducers';
interface Props {
  timeSkjemaLoadedForUser: number;
  automatiskLagring: boolean;
  kanMellomlagres: boolean;
}

const EventListener = ({ timeSkjemaLoadedForUser, automatiskLagring, kanMellomlagres }: Props): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const webcompheader = document.querySelector('hn-webcomp-header');
  HNaddEventListener(HeaderFooterEvents.soonautosignout, webcompheader, () => {
    if (kanMellomlagres) {
      dispatch(lagreSkjema({ timeSkjemaLoadedForUser, automatiskLagring }));
    }
  });

  return <></>;
};

export default EventListener;
