import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { redirectToReturUrlHvisEksisterer } from 'src/store/actions/actionCreators';
import { useAppDispatch, useAppSelector } from 'src/store/reducers';
import { closeContinueOrLeaveMessageAndLeave, closeContinueOrLeaveMessage } from 'src/store/reducers/uiSlice';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

const ConfirmationLightboxLagre = (): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const showContinueOrLeaveMessage = useAppSelector(state => state.refero.ui.showContinueOrLeaveMessage);
  const onCloseLagreMessageAndLeave = (): void => {
    dispatch(closeContinueOrLeaveMessageAndLeave());
    dispatch(redirectToReturUrlHvisEksisterer());
  };

  const onContinueOrLeave = (): void => {
    dispatch(closeContinueOrLeaveMessage());
  };

  return showContinueOrLeaveMessage && resources ? (
    <Modal
      testId="confirmation-lightbox-lagre"
      title={resources.lagreSkjemaTittel}
      description={resources.lagreSkjemaDokumenterBody}
      primaryButtonText={resources.lagreSkjemaButtonFortsettUtfylling}
      secondaryButtonText={resources.lagreSkjemaButtonFortsettSenere}
      onSuccess={onContinueOrLeave}
      onClose={onCloseLagreMessageAndLeave}
    />
  ) : null;
};

export default ConfirmationLightboxLagre;
