import { createSelector } from '@reduxjs/toolkit';

import { getResourcesFromState } from '@helsenorge/framework-utils/resources';

import { RootState } from '../reducers';

//@ts-expect-error - This is a generic function, so we can't know the type of the resources
export const getResourcesSelector = <T>createSelector(
  [(state: RootState, resourceGroup: string) => getResourcesFromState(state, resourceGroup, state.refero.ui.language?.toString())],
  //@ts-expect-error - This is a generic function, so we can't know the type of the resources
  (resources: T) => resources
);
