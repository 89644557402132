// NavigatorMenu.tsx

import React, { useRef } from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';

import { NavigatorSection } from '../../util/getSections';

type Props = {
  toggleNavigatorExpand: () => void;
  setSelectedSection: React.Dispatch<React.SetStateAction<NavigatorSection | null>>;
  sections: NavigatorSection[];
  selectedSection: NavigatorSection | null;
};

export const NavigatorMenu = ({ toggleNavigatorExpand, setSelectedSection, sections, selectedSection }: Props): React.JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const scrollToSelectedSection = (sectionId: string): void => {
    window.location.href = `#${sectionId}-navanchor`;
    window.scrollBy(0, -150);
  };

  return (
    <div ref={wrapperRef} className="page_refero__navigatormenu">
      {sections.map(x => {
        if (x.name) {
          const isActive = selectedSection?.id === x.id;
          return (
            <div key={x.id}>
              <Button
                testId={`navigator-menu-button-${x.id}`}
                variant="borderless"
                className={`page_refero__navmenubutton ${isActive ? 'active' : ''}`}
                onClick={(): void => {
                  scrollToSelectedSection(x.id);
                  setSelectedSection(x);
                  toggleNavigatorExpand();
                }}
              >
                {x.name}
              </Button>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
