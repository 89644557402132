import { Attachment } from 'fhir/r4';
import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import { UploadFile } from '@helsenorge/file-upload/components/file-upload';
import { link } from '@helsenorge/framework-utils/hn-proxy-service';
import { getId, TextMessage } from '@helsenorge/refero';

type UseAttachmentUtilsReturn = {
  generateAttachmentLink: (fileId: string) => string;
  openAttachment: (fileId: string) => void;
  uploadAttachment: (
    files: UploadFile[],
    onSuccess: (attachment: Attachment) => void,
    onError: (errormessage: TextMessage | null) => void
  ) => void;
  deleteAttachment: (fileId: string, onSuccess: () => void) => void;
  getAttachmentLink: (fileId: string) => string;
};

export const useAttachmentUtils = (): UseAttachmentUtilsReturn => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const generateAttachmentLink = (fileId: string): string => {
    return link('skjemainternal', 'api/v1/Attachment', {
      id: fileId,
    });
  };

  const openAttachment = (fileId: string): void => {
    window.open(generateAttachmentLink(fileId), '_blank');
  };

  const uploadAttachment = (
    files: UploadFile[],
    onSuccess: (attachment: Attachment) => void,
    onError: (errormessage: TextMessage | null) => void
  ): void => {
    const reader = new FileReader();
    reader.onload = (): void => {
      const dataString = reader.result?.toString() || '';

      const attachment: Attachment = {
        id: getId(files[0].id),
        title: files[0].name,
        url: `${getId()}-generated`,
        data: dataString.substring(dataString.indexOf(',') + 1),
        contentType: files[0].type,
      };

      onSuccess(attachment);
    };
    reader.onerror = (): void => {
      onError({ Title: resources?.uploadFileError || '', Body: '' });
    };

    reader.readAsDataURL(files[0]);
  };
  const deleteAttachment = (_fileId: string, onSuccess: () => void): void => {
    onSuccess();
  };

  const getAttachmentLink = (fileId: string): string => {
    return fileId.indexOf('generated') > -1 ? '' : generateAttachmentLink(fileId);
  };
  return {
    generateAttachmentLink,
    openAttachment,
    uploadAttachment,
    deleteAttachment,
    getAttachmentLink,
  };
};
