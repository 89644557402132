import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppDispatch, useAppSelector } from 'src/store/reducers';
import { hideAttachmentMaxFilesError } from 'src/store/reducers/uiSlice';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

export const AttachementErrorModal = (): React.JSX.Element | null => {
  const isAttachmentMaxFilesErrorVisible = useAppSelector(state => state.refero.ui.isAttachmentMaxFilesErrorVisible);
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const attachment_field_max_error = resources?.attachment_field_max_error;
  const confirmationActionButton = resources?.confirmationActionButton;

  const dispatch = useAppDispatch();
  return isAttachmentMaxFilesErrorVisible ? (
    <Modal
      variant="error"
      testId="attachment-max-files-error"
      title={'Teknisk feil'}
      onClose={(): void => {
        dispatch(hideAttachmentMaxFilesError());
      }}
      onSuccess={(): void => {
        dispatch(hideAttachmentMaxFilesError());
      }}
      primaryButtonText={confirmationActionButton}
    >
      <NotificationPanel variant="error">{attachment_field_max_error}</NotificationPanel>
    </Modal>
  ) : null;
};
export default AttachementErrorModal;
