import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getLanguagesInDefinition } from 'src/util/getLanguagesInDefinition';

import { LanguagePicker, languagePickerResource } from '@helsenorge/core-framework/language-picker';
import { LanguageLocales } from '@helsenorge/core-utils/constants/languages';
import { setDocumentLanguage } from '@helsenorge/framework-utils/hn-language';

import { userChangeLanguage } from '../store/actions/actionCreators';
import { RootState, useAppDispatch } from '../store/reducers';
import { getPreferredLanguage, isLanguageInFormDefinition } from '../util/portalLanguage-utils';

const sortOrder: { [key: string]: number } = {
  'nb-no': 1,
  'nn-no': 2,
  'se-no': 3,
  'en-gb': 4,
  unknown: 100, // ukjente språk havner bakerst
};

const LanguageSelector = (): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const questionnaires = useSelector((state: RootState) => state.refero.ui.questionnaires);
  const languages = questionnaires && getLanguagesInDefinition(questionnaires);
  const [searchParams, setSearchParams] = useSearchParams();

  const preferredLanguage = getPreferredLanguage(languages);

  const changeUrlDocumentLanguage = (language: LanguageLocales): void => {
    const updatedParams = new URLSearchParams(searchParams);
    // Set the 'lang' parameter to the new language
    updatedParams.set('lang', language);
    setSearchParams(updatedParams);
    setDocumentLanguage(language);
  };

  useEffect(() => {
    if (questionnaires.length <= 0 || !preferredLanguage) {
      return;
    }
    const currentLang = searchParams.get('lang');
    if (currentLang && !isLanguageInFormDefinition(currentLang as LanguageLocales, languages)) {
      const updatedParams = new URLSearchParams(searchParams);
      updatedParams.set('lang', preferredLanguage);
      setSearchParams(updatedParams);
    } else {
      changeUrlDocumentLanguage(preferredLanguage);
    }
  }, [preferredLanguage, questionnaires.length, searchParams]);

  const handleChangeUrlDocumentLanguage = async (language: LanguageLocales | undefined): Promise<void> => {
    if (!language) {
      return;
    }
    await dispatch(userChangeLanguage({ language }));
    changeUrlDocumentLanguage(language);
  };

  const sortedLanguages = [...languages]
    .sort((a: languagePickerResource, b: languagePickerResource) => {
      const sortA = sortOrder[a.locale.toLowerCase()] || sortOrder.unknown;
      const sortB = sortOrder[b.locale.toLowerCase()] || sortOrder.unknown;
      return sortA - sortB;
    })
    .map(x => {
      return { label: x.label, locale: x.locale };
    });
  if (!languages || languages.length <= 1) {
    return <></>;
  }
  return (
    <LanguagePicker
      testId="language-selector"
      className="page_refero__languge-selector"
      selectedLanguage={preferredLanguage}
      languages={sortedLanguages}
      changeLanguage={handleChangeUrlDocumentLanguage}
    />
  );
};

export default LanguageSelector;
