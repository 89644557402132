import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useGetSkjemaErrorTitleAndBodyFromResources } from 'src/hooks/useGetSkjemaErrorTitle';
import { useAppDispatch, useAppSelector } from 'src/store/reducers';
import { dismissSendInnError } from 'src/store/reducers/uiSlice';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

export const SendInnSkjemaErrorModal = (): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const sendInnSkjemaError = useAppSelector(state => state.refero.ui.sendInnSkjemaError);

  const { errorBody, errorTitle } = useGetSkjemaErrorTitleAndBodyFromResources(sendInnSkjemaError);
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));

  const confirmationActionButton = resources?.confirmationActionButton;
  const onSendInnErrorClose = (): void => {
    dispatch(dismissSendInnError());
  };

  return sendInnSkjemaError ? (
    <Modal
      variant="error"
      testId="send-inn-skjema-error"
      onClose={onSendInnErrorClose}
      title={errorTitle}
      description={errorBody}
      onSuccess={onSendInnErrorClose}
      primaryButtonText={confirmationActionButton}
    />
  ) : null;
};
export default SendInnSkjemaErrorModal;
