import React from 'react';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import { getNotificationPanelContent } from './getContent';
import { TopMessageProps } from './TopMessageComponent';
import { ReplaceLinks } from '../navigator/replaceLinks';

export const RenderNotificationPanelMessage = (props: TopMessageProps): React.JSX.Element | null => {
  const { resources, openSidebar } = props;
  const content = getNotificationPanelContent(props);

  if (!content) return null;

  const { message, variant, testId } = content;

  return (
    <NotificationPanel testId={testId} variant={variant}>
      <ReplaceLinks messageText={message} resources={resources} openSidebar={openSidebar} />
    </NotificationPanel>
  );
};
