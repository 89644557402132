import { Questionnaire } from 'fhir/r4';
import { AuthenticationRequirement } from 'src/constants/authenticationRequirement';
import { SaveCapabilites } from 'src/constants/saveCapabilites';
import { SidebarVisibility } from 'src/constants/sidebarVisibility';
import { TopMessageVisibility } from 'src/constants/topMessageVisibility';
import { hasEndpointOrTqqcComponent } from 'src/util';
import {
  getAuthenticationRequirementValue,
  getPrintVersion,
  getSaveCapabilitiesValue,
  getSidebarVisibilityValue,
  getTopMessageVisibilityValue,
} from 'src/util/extension';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { getNavigatorExtension } from '@helsenorge/refero';

export function getPrintReference(skjemaDefinition: Questionnaire | undefined): string | undefined {
  return skjemaDefinition ? getPrintVersion(skjemaDefinition) : '';
}

export function getIsNavigatorEnabled(skjemaDefinition: Questionnaire | undefined): boolean {
  return skjemaDefinition ? !!getNavigatorExtension(skjemaDefinition) : false;
}

export function getIsSavedInDokumenter(skjemaDefinition: Questionnaire | undefined): boolean {
  return skjemaDefinition ? !hasEndpointOrTqqcComponent(skjemaDefinition) : false;
}

export function getSaveCapabilities(skjemaDefinition: Questionnaire | undefined): SaveCapabilites | undefined {
  return skjemaDefinition ? getSaveCapabilitiesValue(skjemaDefinition) : undefined;
}

export function getShouldShowBeforeUnload(original: boolean | undefined, skjemaDefinition: Questionnaire | undefined): boolean | undefined {
  if (!skjemaDefinition) {
    return original;
  }
  const authenticationRequirement = getAuthenticationRequirementValue(skjemaDefinition);
  switch (authenticationRequirement) {
    case AuthenticationRequirement.Anonymous:
    case AuthenticationRequirement.Optional:
      return true;
    default:
      return isAuthorized() ? true : false;
  }
}

export function getSidebarVisibility(skjemaDefinition: Questionnaire | undefined): number {
  if (!skjemaDefinition) return SidebarVisibility.On;
  return getSidebarVisibilityValue(skjemaDefinition);
}

export function getTopMessageVisibility(skjemaDefinition: Questionnaire | undefined): number {
  if (!skjemaDefinition) return TopMessageVisibility.On;
  return getTopMessageVisibilityValue(skjemaDefinition);
}
