import React, { FC, ReactNode } from 'react';

import { createBrowserRouter } from 'react-router-dom';

import PageRoot from '@helsenorge/core-framework/pageroot';
import PendingChanges from '@helsenorge/core-framework/pending-changes';
import { remove } from '@helsenorge/core-framework/pending-changes/pending-changes-state';
import { getPath } from '@helsenorge/framework-utils/hn-page';

import SkjemaContainer from './components';
import { SKJEMA_URL } from './constants/constants';
import { abortSkjema } from './store/actions/actionCreators';
import { useAppDispatch } from './store/reducers';
import { formHasChanges } from './store/reducers/uiSlice';
import { getSkjemaNameFromUrl } from './util';
import { getUrlLanguage } from './util/portalLanguage-utils';

interface PageRootWrapperProps {
  children: ReactNode;
}

const PageRootWrapper: FC<PageRootWrapperProps> = ({ children }): JSX.Element => {
  const dispatch = useAppDispatch();
  const keepChanges = (): void => {
    dispatch(remove());
    dispatch(formHasChanges(false));
  };
  const discardChanges = (): void => {
    dispatch(remove());
    dispatch(abortSkjema());
    dispatch(formHasChanges(false));
  };

  return (
    <>
      <PendingChanges discardChanges={discardChanges} keepChanges={keepChanges} />
      {children}
    </>
  );
};
const tekniskNavn = getSkjemaNameFromUrl();
const skjemaUrl = tekniskNavn ? `/${SKJEMA_URL}/${tekniskNavn}${window.location.search}` : `/${SKJEMA_URL}${window.location.search}`;

export default createBrowserRouter(
  [
    {
      path: '/*',
      element: (
        <PageRoot hasPageHeader={false} redirectTo={skjemaUrl} className="page_refero" language={getUrlLanguage()}>
          <PageRootWrapper>
            <SkjemaContainer />
          </PageRootWrapper>
        </PageRoot>
      ),
    },
  ],
  { basename: `/${getPath()}` }
);
