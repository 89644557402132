import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { WindowActionEnum } from '../types/entities';
import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import SearchIcon from '@helsenorge/designsystem-react/components/Icons/Search';
import Input from '@helsenorge/designsystem-react/components/Input';
import { Breakpoint, FormOnColor, useBreakpoint } from '@helsenorge/designsystem-react';
import { warn } from '@helsenorge/framework-utils/logger';
import SearchAutoSuggest from './search-autosuggest';
import { getMaxSuggestions, getSearchApiUrl, goToSearchResults, fetchSuggestions, updateSuggestionListRef, triggerSearch, handleKeySwitch, handleSuggestions, sanitizeSearchValue, } from './search-utils';
import { getResourcesTestHelper } from '../resources';
import cmsstyles from './styles.module.scss';
export const SearchBox = ({ query = '', contentReference, // definerer kontekst av en avgrenset del av sidetreet (Epi)
referrer, // definerer hvilken side man kommer fra (Epi)
skip, handleChange, getDirectSearchResults, searchInputId, searchUrl = '/sokeside/', label, submitLabel, isAutoSuggestDisabled, isSubmitButtonInverted, isSearchAsYouTypeEnabled, testIdIcon, testId, large = true, autoFocusSearchInput, }) => {
    const [prevQuery, setPrevQuery] = useState(query);
    const [activeQuery, setActiveQuery] = useState(query);
    const [activeAutosuggestionQuery, setActiveAutosuggestionQuery] = useState(null);
    const [cachedAutosuggestions, setCachedAutosuggestions] = useState([]);
    const [shouldShowSuggestions, setShouldShowSuggestions] = useState(false);
    const [highlightedSuggestionIndex, setHighlightedSuggestionIndex] = useState(-1);
    const breakpoint = useBreakpoint();
    const [searchasyoutypeTimeoutId, setSearchasyoutypeTimeoutId] = useState(null);
    const [autosuggestTimeoutId, setAutosuggestTimeoutId] = useState(null);
    const resources = getResourcesTestHelper();
    const searchButtonRef = useRef(null);
    const searchInputRef = useRef(null);
    const listSuggestionsRef = useRef([React.createRef()]);
    // Called on init - updates search params and fetches text from SOT
    useEffect(() => {
        const searchParams = { query, contentReference, referrer, skip };
        if (query && getDirectSearchResults)
            getDirectSearchResults(searchParams);
    }, []);
    // Triggered when the query prop is predefined
    useEffect(() => {
        setActiveQuery(query);
        if (getDirectSearchResults)
            goToSearchResults(searchUrl, WindowActionEnum.None, { query, contentReference, referrer, skip });
    }, [query]);
    const isMobile = breakpoint <= Breakpoint.xs;
    // Fetches suggestions and updates the query and suggestionlist accordingly
    const triggerAutosuggest = async (query) => {
        if (!query || query.length < 2 || isAutoSuggestDisabled) {
            handleSuggestions(query ?? '', [], cachedAutosuggestions, setCachedAutosuggestions);
            return;
        }
        try {
            const matchingAutosuggestion = cachedAutosuggestions.find((autosuggestion) => autosuggestion.query === query);
            let suggestions = [];
            if (matchingAutosuggestion && matchingAutosuggestion.suggestions) {
                suggestions = matchingAutosuggestion.suggestions.filter(r => query !== r);
            }
            else {
                const response = await fetchSuggestions(`${getSearchApiUrl()}${searchUrl}autosuggest?q=${query}`, resources?.searchErrorSuggestionFetch)
                    .then((suggestions) => suggestions)
                    .catch(rejectedError => {
                    handleSuggestions(query, [], cachedAutosuggestions, setCachedAutosuggestions);
                    warn('Feil under henting av autosuggest-treff fra søk', rejectedError);
                    return [];
                });
                suggestions = response ? response.filter(r => query !== r) : [];
            }
            listSuggestionsRef.current = updateSuggestionListRef(suggestions.length, getMaxSuggestions());
            handleSuggestions(query, suggestions, cachedAutosuggestions, setCachedAutosuggestions);
            setActiveAutosuggestionQuery(query);
        }
        catch (error) {
            warn('Feil under oppdatering av autosuggest-treff fra søk', error);
        }
    };
    // Called when using arrow through the list of suggestions
    const handleSuggestionArrowNavigation = (event, index, prevQuery) => {
        if (index === undefined)
            return;
        event.preventDefault();
        setHighlightedSuggestionIndex(index);
        const suggestionListRef = listSuggestionsRef.current || [];
        const currentElement = suggestionListRef[index]?.current;
        if (shouldShowSuggestions) {
            setActiveQuery(currentElement ? currentElement.innerText : prevQuery);
        }
        else {
            setHighlightedSuggestionIndex(-1);
            setActiveQuery(prevQuery);
            triggerAutosuggest(activeQuery);
            setShouldShowSuggestions(true);
        }
    };
    const handleInputChange = (event) => {
        const query = sanitizeSearchValue(event.target.value);
        setActiveQuery(query);
        setPrevQuery(query);
        if (handleChange)
            handleChange(query);
        if (autosuggestTimeoutId != null)
            clearTimeout(autosuggestTimeoutId);
        const newAutoSuggestTimeout = window.setTimeout(() => triggerAutosuggest(query), 500);
        setAutosuggestTimeoutId(newAutoSuggestTimeout);
        if (!getDirectSearchResults)
            return;
        const currentTimeout = searchasyoutypeTimeoutId;
        if (currentTimeout !== null)
            clearTimeout(currentTimeout);
        if (isSearchAsYouTypeEnabled) {
            const newTimeout = window.setTimeout(() => {
                setPrevQuery(activeQuery);
                triggerSearch(searchUrl, { query, contentReference, referrer, skip }, getDirectSearchResults);
            }, 2000);
            setSearchasyoutypeTimeoutId(newTimeout);
        }
    };
    // Function called on submit (click on a suggestion, press enter on a suggestion, or click on submit button)
    const onSearchSubmit = (directQuery) => {
        if ((query && query !== '') || (directQuery && directQuery !== '') || (activeQuery && activeQuery !== '')) {
            handleSuggestions('', [], cachedAutosuggestions, setCachedAutosuggestions);
            setActiveAutosuggestionQuery(null);
            setPrevQuery(activeQuery);
            triggerSearch(searchUrl, { query: directQuery ?? activeQuery, contentReference, referrer, skip }, getDirectSearchResults);
        }
        else if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };
    const handleKeyDown = (event) => {
        handleKeySwitch(event, prevQuery, highlightedSuggestionIndex, listSuggestionsRef, searchasyoutypeTimeoutId, setSearchasyoutypeTimeoutId, handleSuggestionArrowNavigation, setShouldShowSuggestions, onSearchSubmit, setHighlightedSuggestionIndex);
    };
    const handleSuggestionClick = (query) => {
        if (searchasyoutypeTimeoutId !== null)
            clearTimeout(searchasyoutypeTimeoutId);
        setSearchasyoutypeTimeoutId(null);
        setActiveAutosuggestionQuery(null);
        setPrevQuery(activeQuery);
        setActiveQuery(query);
        setShouldShowSuggestions(false);
        handleSuggestions('', [], cachedAutosuggestions, setCachedAutosuggestions);
        triggerSearch(searchUrl, { query, contentReference, referrer, skip }, getDirectSearchResults);
    };
    const searchAutoSuggest = (_jsx(SearchAutoSuggest, { suggestionsRef: listSuggestionsRef, query: activeAutosuggestionQuery, cachedAutosuggestions: cachedAutosuggestions, highlightedSuggestion: highlightedSuggestionIndex, handleKeyDown: handleKeyDown, handleSuggestionClick: handleSuggestionClick, isVisible: shouldShowSuggestions && !isAutoSuggestDisabled }));
    return (_jsx("div", { className: cmsstyles.search, "data-testid": testId, children: _jsx(Input, { errorWrapperClassName: cmsstyles.search__input, onChange: handleInputChange, onKeyDown: handleKeyDown, value: activeQuery, inputId: searchInputId, label: label ? label : resources?.searchLabel, size: large ? 'large' : 'medium', type: "text", "aria-expanded": shouldShowSuggestions, 
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus: autoFocusSearchInput, afterInputChildren: searchAutoSuggest, onColor: isSubmitButtonInverted ? FormOnColor.ondark : undefined, rightOfInput: _jsxs(Button, { ref: searchButtonRef, variant: isSubmitButtonInverted ? 'outline' : undefined, onColor: isSubmitButtonInverted ? 'ondark' : 'onlight', onClick: () => onSearchSubmit(), size: large ? 'large' : 'medium', className: classNames(cmsstyles.search__button, large && cmsstyles['search__button--large']), children: [!isMobile && _jsx(Icon, { testId: testIdIcon, size: 32, color: "white", svgIcon: SearchIcon }), submitLabel ? submitLabel : resources?.searchSubmitLabel] }) }) }));
};
export default SearchBox;
