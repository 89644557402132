import { createSelector } from '@reduxjs/toolkit';
import { HN_SKJEMA_ERRORCODES_RESOURCES } from 'src/constants/constants';
import { HNSkjemaErrorCodes, HNSkjemaFrontend } from 'src/types/Resources';

import { RootState } from '../reducers';
import { getResourcesSelector } from './resourcesSelectors';
const DEFAULT_ERROR_MESSAGE = 'Det har oppstått en feil. Prøv igjen senere.';

export const getErrorMessageFromErrorCodeResource = createSelector(
  [
    (state: RootState): HNSkjemaErrorCodes =>
      getResourcesSelector<HNSkjemaErrorCodes>(state, HN_SKJEMA_ERRORCODES_RESOURCES, state.refero.ui.language),
    (_state: RootState, errorCode: string): string => errorCode,
  ],
  (errorCodesResources, errorCode) => {
    const code = errorCode?.replace('-', '_').replace(/^./, char => char.toLowerCase()) as keyof HNSkjemaErrorCodes;

    return errorCodesResources?.[code] ?? '';
  }
);
export const getErrorMessageFromSkjemaResource = createSelector(
  [
    (state: RootState): HNSkjemaFrontend => getResourcesSelector<HNSkjemaFrontend>(state, HN_SKJEMA_ERRORCODES_RESOURCES),
    (_state: RootState, errorCode: string): string => errorCode,
  ],
  (errorCodesResources, errorCode) => {
    return errorCodesResources?.[errorCode as keyof HNSkjemaFrontend] ?? '';
  }
);
export const getErrorMessageFromErrorCode = createSelector(
  [
    (_state, errorCode?: string): string | undefined => errorCode,
    (state: RootState, errorCode: string): string | undefined => getErrorMessageFromErrorCodeResource(state, errorCode),
    (state: RootState, errorCode: string): string | undefined => getErrorMessageFromSkjemaResource(state, errorCode),
  ],
  (errorCode, errorCodeResourceMessage, skjemaResourcesMessage) => {
    return errorCodeResourceMessage ?? skjemaResourcesMessage ?? errorCode ?? DEFAULT_ERROR_MESSAGE;
  }
);
