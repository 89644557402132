export type NavigatorSection = { id: string; name: string };
export const navigatorSelectorConstant = 'form > section[id]';

export const getSections = (root: Document | HTMLElement): NavigatorSection[] => {
  if (!root && typeof document !== 'undefined') {
    root = document;
  }

  if (!root || typeof root.querySelectorAll !== 'function') {
    return [];
  }
  const sectionNodes = root.querySelectorAll(navigatorSelectorConstant);
  if (!sectionNodes || !sectionNodes.forEach) {
    return [];
  }
  const sections: Array<{ id: string; name: string }> = [];
  sectionNodes.forEach(node => {
    if (node.id !== 'navigator_blindzone') {
      const sectionName = node.getAttribute('data-sectionname');
      if (sectionName && sectionName !== '') {
        sections.push({ id: node.id, name: sectionName });
      }
    }
  });
  return sections;
};
