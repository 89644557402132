import { Questionnaire, QuestionnaireItem, QuestionnaireResponseItem } from 'fhir/r4';

import { getExtension } from '@helsenorge/refero';

import { getActiveItemsWithExtension } from './veileder';
import { AccessibilityToResponse } from '../constants/accessibilityToResponse';
import { AuthenticationRequirement } from '../constants/authenticationRequirement';
import {
  ACCESSIBILITY_TO_RESPONSE,
  AUTHENTICATION_REQUIREMENT_URL,
  GENERATE_PDF,
  INFORMATION_MESSAGE,
  PRINT_VERSION,
  RECIPIENT_URL,
  SAVE_CAPABILITIES,
  SIDEBAR,
} from '../constants/extensions';
import { SaveCapabilites } from '../constants/saveCapabilites';
import { SidebarVisibility } from '../constants/sidebarVisibility';
import { TopMessageVisibility } from '../constants/topMessageVisibility';

export function getSaveCapabilitiesValue(questionnaire: Questionnaire | QuestionnaireItem): SaveCapabilites | undefined {
  const saveCapabilitiesValue = getExtension(SAVE_CAPABILITIES, questionnaire);
  if (!saveCapabilitiesValue || !saveCapabilitiesValue.valueCoding || !saveCapabilitiesValue.valueCoding.code) {
    return undefined;
  }

  return parseInt(saveCapabilitiesValue.valueCoding.code, 10);
}

export function getLastSaveCapabilitiesValue(
  questionnaireItems: QuestionnaireItem[] | undefined,
  responsitems: QuestionnaireResponseItem[] | undefined
): SaveCapabilites | undefined {
  const itemsSaveCapabilitie = getActiveItemsWithExtension(
    SAVE_CAPABILITIES,
    questionnaireItems ? questionnaireItems : [],
    responsitems ? responsitems : []
  );

  const lastSaveCapabilityItem = itemsSaveCapabilitie.slice(-1)[0];
  const lastSaveCapabilityItemValue = lastSaveCapabilityItem
    ? getSaveCapabilitiesValue(lastSaveCapabilityItem.questionnaireItem)
    : undefined;

  return lastSaveCapabilityItemValue;
}

export function getAccessibilityToResponse(questionnaire: Questionnaire): AccessibilityToResponse | undefined {
  const accessibilityToResponse = getExtension(ACCESSIBILITY_TO_RESPONSE, questionnaire);
  if (!accessibilityToResponse || !accessibilityToResponse.valueCoding || !accessibilityToResponse.valueCoding.code) {
    return undefined;
  }

  return parseInt(accessibilityToResponse.valueCoding.code, 10);
}

export function getAuthenticationRequirementValue(questionnaire?: Questionnaire | null): AuthenticationRequirement | undefined {
  const itemAuthenticationRequirement = getExtension(AUTHENTICATION_REQUIREMENT_URL, questionnaire);
  if (!itemAuthenticationRequirement || !itemAuthenticationRequirement.valueCoding || !itemAuthenticationRequirement.valueCoding.code) {
    return undefined;
  }

  return parseInt(itemAuthenticationRequirement.valueCoding.code, 10);
}

export function getPrintVersion(questionniare: Questionnaire): string | undefined {
  const recipientUrl = getExtension(PRINT_VERSION, questionniare);
  return recipientUrl?.valueReference?.reference;
}

export function getRecipientUrl(questionniare: Questionnaire): string | undefined {
  const recipientUrl = getExtension(RECIPIENT_URL, questionniare);
  return recipientUrl?.valueReference?.reference;
}

export function getGeneratePdfExtensionValue(questionnaire: Questionnaire): boolean | undefined {
  const generatepdf = getExtension(GENERATE_PDF, questionnaire);
  return generatepdf?.valueBoolean;
}

export function getSidebarVisibilityValue(questionnaire: Questionnaire): number {
  const sidebarExtension = getExtension(SIDEBAR, questionnaire);
  if (sidebarExtension?.valueCoding?.code) return parseInt(sidebarExtension.valueCoding.code);
  return SidebarVisibility.On;
}

export function getTopMessageVisibilityValue(questionnaire: Questionnaire): number {
  const infoMessageExtension = getExtension(INFORMATION_MESSAGE, questionnaire);
  if (infoMessageExtension?.valueCoding?.code) return parseInt(infoMessageExtension.valueCoding.code);
  return TopMessageVisibility.On;
}
