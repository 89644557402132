import React from 'react';

import { useAppSelector } from 'src/store/reducers';

import Stepper from '@helsenorge/designsystem-react/components/Stepper';

import { shouldFormBeDisplayedAsStepView, getStepArrayLength } from './utils';

type Props = {
  isMicroweb?: boolean;
  stepIndex: number;
};

export const StepViewComponent = ({ isMicroweb, stepIndex }: Props): React.JSX.Element | null => {
  const formDefinition = useAppSelector(state => state.refero.form.FormDefinition);
  const shouldRenderComponent = formDefinition && !isMicroweb && shouldFormBeDisplayedAsStepView(formDefinition);
  if (!shouldRenderComponent) {
    return null;
  }
  return (
    <span className="page_refero__stepper-wrapper">
      <Stepper value={stepIndex} max={getStepArrayLength(formDefinition)} testId="stepper" />
    </span>
  );
};
export default StepViewComponent;
