import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppDispatch, useAppSelector } from 'src/store/reducers';
import { dismissAnonymousMessage } from 'src/store/reducers/uiSlice';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';
import { getLoginUrl } from 'src/util';

import Modal from '@helsenorge/designsystem-react/components/Modal';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';

export const AnonomousSkjemaLightbox = (): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const showAnonymousSkjemaLightbox = useAppSelector(state => state.refero.ui.showAnonymousSkjemaLightbox);
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const skjemaLoginButton = resources?.skjemaLoginButton;
  const formCancel = resources?.formCancel;
  const skjemaLoginMessageAuthenticationRequirementRequired = resources?.skjemaLoginMessageAuthenticationRequirementRequired;

  const handleHideAnonymousSkjemaLightbox = (): void => {
    dispatch(dismissAnonymousMessage());
  };
  return showAnonymousSkjemaLightbox && !isAuthorized() ? (
    <Modal
      testId="anonymous-skjema-lightbox"
      title={'Logg inn'}
      primaryButtonText={skjemaLoginButton}
      secondaryButtonText={formCancel}
      onSuccess={(): void => {
        window.history.replaceState(window.history.state, '', getLoginUrl());
        window.location.reload();
      }}
      onClose={handleHideAnonymousSkjemaLightbox}
    >
      <NotificationPanel variant="info" testId="notification-panel-anonymous-skjema-lightbox-info">
        {skjemaLoginMessageAuthenticationRequirementRequired}
      </NotificationPanel>
    </Modal>
  ) : null;
};
