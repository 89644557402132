import { Questionnaire } from 'fhir/r4';
import { DEFAULT_LANGUAGE } from 'src/constants/constants';

import { languagePickerResource } from '@helsenorge/core-framework/language-picker';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';

import { getLanguageDisplay } from './getLanguageDisplay';

export const getLanguageLocaleFromString = (language?: string | null): LanguageLocales | undefined => {
  if (!language) {
    return undefined;
  }
  return Object.values(LanguageLocales).includes(language as LanguageLocales) ? (language as LanguageLocales) : undefined;
};
export const getLanguagesInDefinition = (questionnaires: Questionnaire[]): languagePickerResource[] => {
  return questionnaires?.map(q => {
    return {
      locale: getLanguageLocaleFromString(q.language ?? DEFAULT_LANGUAGE) ?? DEFAULT_LANGUAGE,
      label: getLanguageDisplay(q) || '',
    };
  });
};
