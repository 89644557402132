import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppDispatch, useAppSelector } from 'src/store/reducers';
import { hideAnonymousUploadOfAttachmentDenied } from 'src/store/reducers/uiSlice';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

export const AnonymousUploadDeniedModal = (): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const showAnonymousUploadDenied = useAppSelector(state => state.refero.ui.showAnonymousUploadDenied);
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));

  const anonymousUploadTitle = resources?.anonymousUploadTitle || '';
  const anonymousUploadDescription = resources?.anonymousUploadDescription;

  const onAnonymousUploadDeniedClose = (): void => {
    dispatch(hideAnonymousUploadOfAttachmentDenied());
  };
  return showAnonymousUploadDenied ? (
    <Modal
      testId="anonymous-upload-denied"
      title={anonymousUploadTitle}
      onClose={onAnonymousUploadDeniedClose}
      onSuccess={onAnonymousUploadDeniedClose}
    >
      <NotificationPanel variant="info">{anonymousUploadDescription}</NotificationPanel>
    </Modal>
  ) : null;
};

export default AnonymousUploadDeniedModal;
