import React from 'react';

import { setDefaultOptions } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { createRoot } from 'react-dom/client';

import { captureErrors } from '@helsenorge/framework-utils/logger';

import RootComponent from './root/index';
import { store } from './store/reducers';
setDefaultOptions({ locale: enGB });

captureErrors();

const anchor = document.getElementById('main-content-wrapper');

if (!anchor) {
  throw new Error("No element with id 'main-content-wrapper' found.");
}

const root = createRoot(anchor);

if (import.meta.env.MODE === 'mocks') {
  import('./__mocks__/browser')
    .then(({ worker }) =>
      worker.start({
        onUnhandledRequest: 'bypass',
      })
    )
    .then(() =>
      root.render(
        <React.StrictMode>
          <RootComponent store={store} />
        </React.StrictMode>
      )
    );
} else {
  root.render(
    <React.StrictMode>
      <RootComponent store={store} />
    </React.StrictMode>
  );
}
