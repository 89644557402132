import React from 'react';

import { Metadata } from '../../types/MinHelseEntities';
import { HNSkjemaFrontend } from '../../types/Resources';

import { RenderFormFillingInformation } from './GetFormFillingInformation';
import { RenderNotificationPanelMessage } from './RenderNotificatioinPanelMessage';
import { AuthenticationRequirement } from '../../constants/authenticationRequirement';

export type TopMessageProps = {
  resources: HNSkjemaFrontend;
  authenticationRequirement?: AuthenticationRequirement;
  userMetadata: Metadata;
  printReference?: string;
  openSidebar: () => void;
  visibility: number | undefined;
};

const TopMessage = (props: TopMessageProps): React.JSX.Element | null => {
  return (
    <div data-testid="top-message">
      <RenderNotificationPanelMessage {...props} />
      <RenderFormFillingInformation {...props} />
    </div>
  );
};

export default TopMessage;
