import { FEIL, HN_SKJEMA_FRONTEND_RESOURCES, LOGGETUT_FEILCODE } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getErrorMessageFromErrorCode } from 'src/store/selectors/errorCodesSelectors';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';
import { hentOversettelse } from 'src/util/stringToLanguageLocales';

export const useGetSkjemaErrorTitleFromResources = (error?: string): string => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  if (!resources) {
    return FEIL;
  }

  return error === LOGGETUT_FEILCODE
    ? hentOversettelse('loggetut_feil_tittel', resources)
    : hentOversettelse('sendInnSkjemaErrorTitle', resources);
};

export const useGetSkjemaErrorBodyFromResources = (): string => {
  const sendInnSkjemaErrorCode = useAppSelector(state => state.refero.ui.sendInnSkjemaErrorCode);
  const errorMessage = useAppSelector(state => getErrorMessageFromErrorCode(state, sendInnSkjemaErrorCode));
  // const isSavedInDokumenter = useAppSelector(state => state.refero.ui.isSavedInDokumenter);
  if (!errorMessage) {
    return '';
  }

  // const errorBody =
  //   error === LOGGETUT_FEILCODE ? hentOversettelse('loggetut_feil_body', resources) : hentOversettelse('sendInnSkjemaErrorBody', resources);
  return errorMessage;
  // (
  //   hentOversettelse(error as keyof HNSkjemaFrontend, resources) ||
  //   (isSavedInDokumenter ? hentOversettelse('fullforSkjemaFeilBody', resources) : errorBody)
  // );
};

export const useGetSkjemaErrorTitleAndBodyFromResources = (error?: string): { errorTitle: string; errorBody: string } => {
  return {
    errorTitle: useGetSkjemaErrorTitleFromResources(error),
    errorBody: useGetSkjemaErrorBodyFromResources(),
  };
};
