import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cn from 'classnames';
import Title from '@helsenorge/designsystem-react/components/Title';
import LinkList from './linklist';
import styles from './styles.module.scss';
const LinkListBlock = ({ links, title, titleHtmlMarkup = 'h2', additionalLinkList, color = 'neutral', useGridLayout = false, children, }) => {
    const contentClassName = cn(styles['linklistblock__content'], {
        [styles['linklistblock__content--neutral']]: color === 'neutral',
        [styles['linklistblock__content--cherry']]: color === 'cherry',
        [styles['linklistblock__content--blueberry']]: color === 'blueberry',
        [styles['linklistblock__content--grid']]: useGridLayout,
    });
    return (_jsx("div", { className: styles['linklistblock'], children: _jsxs("div", { className: contentClassName, children: [title && (_jsx(Title, { htmlMarkup: titleHtmlMarkup, appearance: "title3", margin: { marginTop: 0, marginBottom: 1 }, children: title })), children, _jsx(LinkList, { color: color, links: links }), additionalLinkList && (_jsx(LinkList, { links: additionalLinkList, color: color, size: "small", className: styles['linklistblock__additional-link-list'] }))] }) }));
};
export default LinkListBlock;
