// useSectionScroll.ts

import { useEffect, useRef } from 'react';

import { NavigatorSection } from '../../util/getSections';

interface UseSectionScrollProps {
  sections: NavigatorSection[];
  setSelectedSection: React.Dispatch<React.SetStateAction<NavigatorSection | null>>;
  offset?: number;
}

export const useSectionScroll = ({ sections, setSelectedSection, offset = 150 }: UseSectionScrollProps): void => {
  const sectionsRef = useRef(sections);
  const selectedSectionRef = useRef<NavigatorSection | null>(null);

  // Update refs whenever sections change
  useEffect(() => {
    sectionsRef.current = sections;
  }, [sections]);

  // Scroll event listener to update selectedSection based on scroll position
  useEffect(() => {
    let ticking = false;

    const handleScroll = (): void => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const scrollY = window.scrollY + offset;

          let currentSection: NavigatorSection | null = null;

          // Loop through sections to find the current section
          for (let i = sectionsRef.current.length - 1; i >= 0; i--) {
            const section = sectionsRef.current[i];
            const sectionElement = document.getElementById(section.id);
            const sectionTop = getYCoordinates(sectionElement);

            if (sectionTop !== undefined && scrollY >= sectionTop) {
              currentSection = section;
              break;
            }
          }

          // Update selectedSection if it has changed
          if (currentSection && currentSection.id !== selectedSectionRef.current?.id) {
            setSelectedSection(currentSection);
            selectedSectionRef.current = currentSection;
          }

          // Handle navigator opacity
          const navigatorElements = document.getElementsByClassName('page_refero__navigator') as HTMLCollectionOf<HTMLElement>;

          if (navigatorElements.length > 0 && sectionsRef.current.length > 0) {
            const firstSection = document.getElementById(sectionsRef.current[0].id);
            const firstSectionYCoordinates = getYCoordinates(firstSection);

            if (firstSectionYCoordinates && scrollY > firstSectionYCoordinates - offset) {
              navigatorElements[0].style.opacity = '100%';
            } else {
              navigatorElements[0].style.opacity = '0%';
            }
          }

          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [offset, setSelectedSection]);

  // Helper function to get Y coordinates
  const getYCoordinates = (element: HTMLElement | null): number | undefined => {
    if (element) {
      const rect = element.getBoundingClientRect();
      return rect.top + window.scrollY;
    }
  };
};
