import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { Questionnaire, QuestionnaireResponse } from 'fhir/r4';

import { Metadata, TextMessage } from '../../types/MinHelseEntities';
import { HNSkjemaFrontend } from '../../types/Resources';

import { HelsenorgeProxyError } from '@helsenorge/framework-utils/hn-proxy-service';
import { SET_SKJEMA_DEFINITION } from '@helsenorge/refero';

export type SkjemaAction = PayloadAction<
  SkjemaActionPayload,
  | RECEIVE_SKJEMAOPPGAVE
  | RECEIVE_SKJEMA_DEFINITION
  | FAILURE_SKJEMAOOPPGAVE
  | SKJEMA_LOADED
  | SET_SKJEMA_DEFINITION
  | SHOULD_SHOW_BEFORE_UNLOAD
>;

export type SkjemaActionPayload = {
  questionnaireResponse?: QuestionnaireResponse;
  dokumentGuid?: string;
  samtaleGuid?: string;
  questionnaire?: Questionnaire;
  language?: string | null;
  newFormDefinitionUrl?: string | undefined;
  error?: string | HelsenorgeProxyError;
  warning?: TextMessage;
  userMetadata?: Metadata;
  blockSubmit?: boolean;
  show?: boolean;
  version?: string;
  resources?: HNSkjemaFrontend;
  questionnaires?: Array<Questionnaire>;
  taskId?: string;
  returUrl?: string;
};
export type SkjemaDefinisjonLoadedPayload = Pick<
  SkjemaActionPayload,
  'questionnaires' | 'userMetadata' | 'warning' | 'dokumentGuid' | 'taskId' | 'returUrl'
>;
export type SetResourcesPayload = Pick<SkjemaActionPayload, 'resources'>;
export type ShouldShowBeforeUnloadPayload = Pick<SkjemaActionPayload, 'show'>;
export type BlockSubmitPayload = Pick<SkjemaActionPayload, 'blockSubmit'>;
export type PromtLoginMessagePayload = Pick<SkjemaActionPayload, 'questionnaire'>;
export type SkjemaFeiletPayload = Pick<SkjemaActionPayload, 'error'>;
export type SendInnSkjemaDonePayload = Pick<SkjemaActionPayload, 'samtaleGuid'>;
export type LagreSkjemaDonePayload = Pick<SkjemaActionPayload, 'dokumentGuid'>;

export type RECEIVE_SKJEMAOPPGAVE = typeof RECEIVE_SKJEMAOPPGAVE;
export const RECEIVE_SKJEMAOPPGAVE = 'skjemautfyller/RECEIVE_SKJEMAOPPGAVE';

export type RECEIVE_SKJEMA_DEFINITION = typeof RECEIVE_SKJEMA_DEFINITION;
export const RECEIVE_SKJEMA_DEFINITION = 'skjemautfyller/RECEIVE_SKJEMA_DEFINITION';

export const FAILURE_SKJEMAOOPPGAVE = 'skjemautfyller/FAILURE_SKJEMAOOPPGAVE';
export type FAILURE_SKJEMAOOPPGAVE = typeof FAILURE_SKJEMAOOPPGAVE;

export type SHOULD_SHOW_BEFORE_UNLOAD = typeof SHOULD_SHOW_BEFORE_UNLOAD;
export const SHOULD_SHOW_BEFORE_UNLOAD = 'skjemautfyller/SHOULD_SHOW_BEFORE_UNLOAD';

export type SKJEMA_LOADED = typeof SKJEMA_LOADED;
export const SKJEMA_LOADED = 'skjemautfyller/SKJEMA_LOADED';

export const LOAD_VALUESET = 'skjemautfyller/LOAD_VALUESET';
export type LOAD_VALUESET = typeof LOAD_VALUESET;

export const shouldShowBeforeUnload = createAction<ShouldShowBeforeUnloadPayload, SHOULD_SHOW_BEFORE_UNLOAD>(SHOULD_SHOW_BEFORE_UNLOAD);
