import { useEffect } from 'react';

import { useAppSelector } from 'src/store/reducers';
import { jsonStringifySortProps } from 'src/util';

import { add, remove } from '@helsenorge/core-framework/pending-changes/pending-changes-state';
import { useAppDispatch } from '@helsenorge/refero';

const useFormHasChanges = (): boolean => {
  const shouldShowBeforeUnload = useAppSelector(state => state.refero.ui.shouldShowBeforeUnload);
  const formHasChanges = useAppSelector(state => state.refero.ui.formHasChanges);
  const lastSavedDataItem = useAppSelector(state => state.refero.ui.lastSavedData?.item);
  const item = useAppSelector(state => state.refero.form.FormData.Content?.item);
  const dispatch = useAppDispatch();
  const hasChanges = !!(
    shouldShowBeforeUnload &&
    lastSavedDataItem &&
    item &&
    jsonStringifySortProps(item) !== jsonStringifySortProps(lastSavedDataItem) &&
    formHasChanges === true
  );
  useEffect(() => {
    if (hasChanges) {
      dispatch(add());
    } else {
      dispatch(remove());
    }
  }, [hasChanges]);

  return hasChanges;
};
export default useFormHasChanges;
