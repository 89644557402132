import { useEffect, useState } from 'react';

import { useAppSelector } from 'src/store/reducers';
import { getSections, NavigatorSection } from 'src/util/getSections';

type UseNavigatiorReturn = {
  isNavigatorInitiated: boolean;
  sections: NavigatorSection[];
  setSections: (sections: NavigatorSection[]) => void;
};

export const useNavigator = (lastActivity: number): UseNavigatiorReturn => {
  const [isNavigatorInitiated, setIsNavigatorInitiated] = useState(false);
  const [sections = [], setSections] = useState(getSections(document));

  const isNavigatorEnabled = useAppSelector(state => state.refero.ui.isNavigatorEnabled);
  const sectionsLength = getSections(document).length;

  useEffect(() => {
    setSections(getSections(document));
  }, [lastActivity]);

  useEffect(() => {
    if (isNavigatorEnabled && !isNavigatorInitiated && sectionsLength > 0) {
      setIsNavigatorInitiated(true);
    }
  }, [isNavigatorEnabled, isNavigatorInitiated, sectionsLength]);
  return { isNavigatorInitiated, sections, setSections };
};
