import React from 'react';

import classNames from 'classnames';

import SafeHTML from '@helsenorge/framework-utils/components/safe-html';

type Props = {
  helpText: string;
  opening: boolean;
};

const HelpElement = ({ helpText, opening }: Props): React.JSX.Element => {
  const collapseClasses: string = classNames({
    page_refero__helpComponent: true,
    'page_refero__helpComponent--open': opening,
  });
  return opening ? <SafeHTML testId="help-element" className={collapseClasses} html={helpText} tagName="div" /> : <></>;
};
export default HelpElement;
