import { languagePickerResource } from '@helsenorge/core-framework/language-picker';
import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { getUserLanguage } from '@helsenorge/framework-utils/hn-language';

import { getLanguageLocaleFromString } from './getLanguagesInDefinition';
import { DEFAULT_LANGUAGE } from '../constants/constants';

export const isValidPortalLanguage = (language: LanguageLocales): boolean => {
  if (
    language === LanguageLocales.NORWEGIAN ||
    language === LanguageLocales.NORWEGIAN_NYNORSK ||
    language === LanguageLocales.ENGLISH ||
    language === LanguageLocales.SAMI_NORTHERN
  ) {
    return true;
  } else {
    return false;
  }
};

export const getPortalLanguage = (): LanguageLocales => {
  const userLanguage = getUserLanguage();
  const language = getLanguageLocaleFromString(userLanguage);
  return language && isValidPortalLanguage(language) ? language : DEFAULT_LANGUAGE;
};

export const isLanguageInFormDefinition = (portalLanguage: LanguageLocales, languagesInDefinition: languagePickerResource[]): boolean => {
  const languageExists = languagesInDefinition.some(x => x.locale === portalLanguage);
  return languageExists;
};

export const getUrlLanguage = (): LanguageLocales | undefined => {
  const params = new URLSearchParams(window.location.search);
  if (params.has('lang')) {
    return getLanguageLocaleFromString(params.get('lang'));
  }
  return undefined;
};

export const getPreferredLanguage = (languagesInFormDefinition: languagePickerResource[]): LanguageLocales => {
  const languageFromQueryString = getUrlLanguage();
  const portalLanguage = getPortalLanguage();
  const params = new URLSearchParams(window.location.search);
  if (languagesInFormDefinition.length === 0) {
    return DEFAULT_LANGUAGE;
  } else if (params.has('lang')) {
    return languageFromQueryString && isLanguageInFormDefinition(portalLanguage, languagesInFormDefinition)
      ? languageFromQueryString
      : DEFAULT_LANGUAGE;
  } else if (portalLanguage) {
    return isLanguageInFormDefinition(portalLanguage, languagesInFormDefinition) ? portalLanguage : DEFAULT_LANGUAGE;
  }
  return DEFAULT_LANGUAGE;
};
