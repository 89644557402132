import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { SidebarVisibility } from 'src/constants/sidebarVisibility';
import { GlobalState, useAppSelector } from 'src/store/reducers';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';

import Sidebar from './sidebar';

type Props = {
  toggleSidebarOpen: (isOpen: boolean) => void;
  isSidebarOpen: boolean;
};
const RenderSidebar = ({ toggleSidebarOpen, isSidebarOpen }: Props): React.JSX.Element | null => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const sidebarData = useAppSelector((state: GlobalState) => state.refero.ui.sidebarData);
  const userMetadata = useAppSelector((state: GlobalState) => state.refero.ui.userMetadata);
  const formDefinition = useAppSelector((state: GlobalState) => state.refero.form.FormDefinition);
  const sidebarVisibility = useAppSelector((state: GlobalState) => state.refero.ui.sidebarVisibility);
  const isSavedInDokumenter = useAppSelector((state: GlobalState) => state.refero.ui.isSavedInDokumenter);
  const kanLagresEtterInnsending = useAppSelector((state: GlobalState) => state.refero.ui.kanLagresEtterInnsending);
  const printReference = useAppSelector((state: GlobalState) => state.refero.ui.printReference);

  const closeSidebar = (): void => {
    toggleSidebarOpen(false);
  };

  if (!resources || !sidebarData || !formDefinition?.Content?.title || !userMetadata || sidebarVisibility === SidebarVisibility.Off) {
    return null;
  }

  return (
    <Sidebar
      isOpen={isSidebarOpen}
      toggleShowSidebar={(): void => toggleSidebarOpen(!isSidebarOpen)}
      sidebarData={sidebarData}
      hideSidebar={closeSidebar}
      showSidebar={() => toggleSidebarOpen(true)}
      resources={resources}
      formTitle={formDefinition.Content.title}
      kanSendes={!isSavedInDokumenter}
      kanLagres={kanLagresEtterInnsending}
      harRepresentasjonsMulighet={isAuthorized() && !userMetadata.CanOnlyBePerformedBySubject}
      printReference={isAuthorized() ? undefined : printReference}
    />
  );
};
export default RenderSidebar;
