import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Questionnaire, QuestionnaireResponse } from 'fhir/r4';
import { fetchSkjemaoppgave, lagreSkjema, sendInnSkjema, userChangeLanguage } from 'src/store/actions/actionCreators';

import { TextMessage, Metadata } from '../../types/MinHelseEntities';

import Language from '@helsenorge/core-utils/constants/languages';
import { getSidebarSections, setSkjemaDefinitionAction, SidebarItem } from '@helsenorge/refero';

import {
  getIsNavigatorEnabled,
  getIsSavedInDokumenter,
  getPrintReference,
  getSaveCapabilities,
  getShouldShowBeforeUnload,
  getSidebarVisibility,
  getTopMessageVisibility,
} from './selectors';
import { GUIDANCE_ACTION } from '../../constants/extensions';
import { SaveCapabilites } from '../../constants/saveCapabilites';
import { hasExtension, hasEndpoint, getSkjemaNameFromUrl } from '../../util/index';
import { trackFormEvent } from '../../util/trackFormEvent';
import { shouldShowBeforeUnload, PromtLoginMessagePayload, BlockSubmitPayload } from '../actions/actions';
import { ProxyErrorResponse, UNKNOWN_ERROR_CODE } from '../actions/errorHandling';

export type UiState = {
  language: Language;
  loadSkjemaoppgaveErrorMessage?: string;
  loadSkjemaoppgaveErrorCode?: string;
  showGlobalSpinner?: boolean;
  showLocalSpinner?: boolean;

  // innsending/ferdigstilling
  sendInnSkjemaError?: string;
  sendInnSkjemaErrorCode?: string;
  skjemaSendtInn?: boolean;
  skjemaSendesInn?: boolean;
  samtaleGuid?: string;
  isAttachmentMaxFilesErrorVisible?: boolean;

  // mellomlagring
  lagreSkjemaError?: string;
  showContinueOrLeaveMessage?: boolean;
  dokumentGuid?: string;
  lastSavedData?: QuestionnaireResponse;
  kanIkkeLagre?: boolean;
  lagreSkjemaErrorCode?: string;

  blockSubmit?: boolean;
  shouldShowBeforeUnload?: boolean;
  showAnonymousUploadDenied?: boolean;
  showAnonymousSkjemaLightbox?: boolean;
  notUsedForm?: boolean;

  // loading bundle:
  questionnaires: Array<Questionnaire>;
  userMetadata?: Metadata;
  loadSkjemaoppgaveWarningMessage?: TextMessage;
  taskId?: string;
  returUrl?: string;

  // derived from current selected questionnaire
  isSavedInDokumenter?: boolean;
  isNavigatorEnabled?: boolean;
  kanMellomlagres: boolean;
  kanLagresEtterInnsending: boolean;
  printReference?: string;
  sidebarData?: Array<SidebarItem>;
  sidebarVisibility?: number;
  topMessageVisibility?: number;
  harGuidanceAction?: boolean;
  harEndepunkt?: boolean;
  formHasChanges: boolean;
};

export const initialState: UiState = {
  language: Language.NORWEGIAN,
  sendInnSkjemaErrorCode: undefined,
  showGlobalSpinner: false,
  showLocalSpinner: false,
  sendInnSkjemaError: undefined,
  skjemaSendtInn: false,
  skjemaSendesInn: false,
  lagreSkjemaError: undefined,
  kanIkkeLagre: false,
  blockSubmit: undefined,
  shouldShowBeforeUnload: true,
  isSavedInDokumenter: false,
  showAnonymousUploadDenied: false,
  showAnonymousSkjemaLightbox: false,
  notUsedForm: true,
  showContinueOrLeaveMessage: false,
  dokumentGuid: undefined,
  kanMellomlagres: true,
  kanLagresEtterInnsending: true,
  sidebarData: undefined,
  questionnaires: [],
  userMetadata: undefined,
  loadSkjemaoppgaveWarningMessage: undefined,
  formHasChanges: false,
};

const reducer = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    formHasChanges(state, action: PayloadAction<boolean>) {
      state.formHasChanges = action.payload;
    },
    dismissAnonymousMessage: state => {
      state.showAnonymousSkjemaLightbox = false;
    },
    startSendInnSkjema: state => {
      state.showGlobalSpinner = true;
      state.sendInnSkjemaError = undefined;
      state.sendInnSkjemaErrorCode = undefined;

      state.skjemaSendtInn = false;
      state.skjemaSendesInn = true;
    },
    dismissSendInnError: state => {
      state.sendInnSkjemaError = undefined;
      state.sendInnSkjemaErrorCode = undefined;
    },
    dismissLagreError: state => {
      state.lagreSkjemaError = undefined;
      state.lagreSkjemaErrorCode = undefined;
    },
    kanIkkeLagreError: state => {
      state.kanIkkeLagre = true;
    },
    dismissKanIkkeLagre: state => {
      state.kanIkkeLagre = false;
    },
    anonymousUploadOfAttachmentDenied: state => {
      state.showAnonymousUploadDenied = true;
    },
    hideAnonymousUploadOfAttachmentDenied: state => {
      state.showAnonymousUploadDenied = false;
    },
    closeContinueOrLeaveMessageAndLeave: state => {
      state.showContinueOrLeaveMessage = false;
      state.showGlobalSpinner = true;
    },
    closeContinueOrLeaveMessage: state => {
      state.showContinueOrLeaveMessage = false;
    },
    showAttachmentMaxFilesError: state => {
      state.isAttachmentMaxFilesErrorVisible = true;
    },
    hideAttachmentMaxFilesError: state => {
      state.isAttachmentMaxFilesErrorVisible = false;
    },
    promptLoginMessage: (state, action: PayloadAction<PromtLoginMessagePayload>) => {
      if (state.notUsedForm) {
        state.shouldShowBeforeUnload = getShouldShowBeforeUnload(state.shouldShowBeforeUnload, action.payload.questionnaire);
        state.showAnonymousSkjemaLightbox = !getShouldShowBeforeUnload(state.shouldShowBeforeUnload, action.payload.questionnaire);
        state.notUsedForm = false;
      }
    },
    setSamtaleguid: (state, action: PayloadAction<{ samtaleGuid?: string }>) => {
      state.samtaleGuid = action.payload.samtaleGuid;
      state.skjemaSendesInn = false;
      state.skjemaSendtInn = true;
      state.showGlobalSpinner = false;
    },
    blockSubmit: (state, action: PayloadAction<BlockSubmitPayload>) => {
      state.blockSubmit = action.payload.blockSubmit;
    },
  },
  extraReducers: builder => {
    builder.addCase(userChangeLanguage.fulfilled, (state, action) => {
      state.language = action.payload.language;
    });
    builder.addCase(setSkjemaDefinitionAction, (state, action) => {
      state.shouldShowBeforeUnload = getShouldShowBeforeUnload(state.shouldShowBeforeUnload, action.payload.questionnaire);
      const saveCapabilities = getSaveCapabilities(action.payload.questionnaire);
      state.kanMellomlagres = saveCapabilities !== SaveCapabilites.FinalVersionOnly && saveCapabilities !== SaveCapabilites.NoSaving;
      state.kanLagresEtterInnsending = saveCapabilities !== SaveCapabilites.NoSaving;
      state.isSavedInDokumenter = getIsSavedInDokumenter(action.payload.questionnaire);
      state.printReference = getPrintReference(action.payload.questionnaire);
      state.isNavigatorEnabled = getIsNavigatorEnabled(action.payload.questionnaire);
      state.sidebarData = action.payload.questionnaire ? getSidebarSections(action.payload.questionnaire) : undefined;
      state.sidebarVisibility = getSidebarVisibility(action.payload.questionnaire);
      state.topMessageVisibility = getTopMessageVisibility(action.payload.questionnaire);
      state.harGuidanceAction = hasExtension(action.payload.questionnaire, GUIDANCE_ACTION);
      state.harEndepunkt = hasEndpoint(action.payload.questionnaire);
      state.lastSavedData = action.payload.questionnaireResponse ? action.payload.questionnaireResponse : state.lastSavedData;
    });
    builder.addCase(shouldShowBeforeUnload, (state, action) => {
      state.shouldShowBeforeUnload = action.payload.show;
    });
    builder.addCase(fetchSkjemaoppgave.pending, state => {
      state.showLocalSpinner = true;
      state.loadSkjemaoppgaveErrorMessage = undefined;
      state.loadSkjemaoppgaveErrorCode = undefined;
      state.formHasChanges = false;
    });
    builder.addCase(fetchSkjemaoppgave.fulfilled, (state, action) => {
      trackFormEvent('form start', getSkjemaNameFromUrl());
      state.dokumentGuid = action.payload.dokumentGuid;
      state.taskId = action.payload.taskId;
      state.returUrl = action.payload.returUrl;
      state.showLocalSpinner = false;
      state.showAnonymousSkjemaLightbox = false;
      state.questionnaires = action.payload.questionnaires || [];
      state.userMetadata = action.payload.userMetadata;
      state.loadSkjemaoppgaveWarningMessage = action.payload.warning;
    });
    builder.addCase(fetchSkjemaoppgave.rejected, (state, action) => {
      if (action.payload && typeof action.payload === 'string') {
        state.loadSkjemaoppgaveErrorMessage = action.payload;
        state.loadSkjemaoppgaveErrorCode = action.payload;
      } else {
        const error = action.payload as ProxyErrorResponse;
        state.loadSkjemaoppgaveErrorCode = error.Code ?? UNKNOWN_ERROR_CODE;
        state.loadSkjemaoppgaveErrorMessage = error.Message ?? 'An unknown error occurred';
      }
      state.loadSkjemaoppgaveWarningMessage = undefined;
      state.showLocalSpinner = false;
    });
    builder.addCase(lagreSkjema.pending, state => {
      state.showGlobalSpinner = true;
      state.lagreSkjemaError = undefined;
      state.lagreSkjemaErrorCode = undefined;
      state.formHasChanges = false;
    });
    builder.addCase(lagreSkjema.fulfilled, (state, action) => {
      trackFormEvent('form save', getSkjemaNameFromUrl());
      state.lagreSkjemaError = undefined;
      state.lagreSkjemaErrorCode = undefined;
      state.showContinueOrLeaveMessage = true;
      state.dokumentGuid = action.payload?.dokumentGuid;
    });
    builder.addCase(lagreSkjema.rejected, (state, action) => {
      if (action.payload && typeof action.payload === 'string') {
        state.lagreSkjemaError = action.payload;
        state.lagreSkjemaErrorCode = action.payload;
      } else {
        const error = action.payload as ProxyErrorResponse;
        state.lagreSkjemaError = error?.Message;
        state.lagreSkjemaErrorCode = error?.Code;
      }
    });
    builder.addCase(sendInnSkjema.pending, state => {
      state.showGlobalSpinner = true;
      state.lagreSkjemaError = undefined;
      state.lagreSkjemaErrorCode = undefined;
      state.shouldShowBeforeUnload = false;
      state.formHasChanges = false;
    });
    builder.addCase(sendInnSkjema.fulfilled, (state, action) => {
      trackFormEvent('form complete', getSkjemaNameFromUrl());
      state.samtaleGuid = action.payload.samtaleGuid ? action.payload.samtaleGuid : state.samtaleGuid;
      state.skjemaSendtInn = true;
    });
    builder.addCase(sendInnSkjema.rejected, (state, action) => {
      if (action.payload && typeof action.payload === 'string') {
        state.sendInnSkjemaError = action.payload;
        state.sendInnSkjemaErrorCode = action.payload;
      } else {
        const error = action.payload as ProxyErrorResponse;
        state.sendInnSkjemaErrorCode = error.Code;
        state.sendInnSkjemaError = error.Message ?? error.Message;
      }
    });
    builder.addMatcher(sendInnSkjema.settled, state => {
      state.showGlobalSpinner = false;
      state.skjemaSendesInn = false;
    });
    builder.addMatcher(lagreSkjema.settled, state => {
      state.showGlobalSpinner = false;
    });
  },
});
export default reducer.reducer;
export const {
  dismissAnonymousMessage,
  startSendInnSkjema,
  dismissSendInnError,
  dismissLagreError,
  kanIkkeLagreError,
  dismissKanIkkeLagre,
  anonymousUploadOfAttachmentDenied,
  hideAnonymousUploadOfAttachmentDenied,
  closeContinueOrLeaveMessageAndLeave,
  closeContinueOrLeaveMessage,
  showAttachmentMaxFilesError,
  hideAttachmentMaxFilesError,
  promptLoginMessage,
  setSamtaleguid,
  blockSubmit,
  formHasChanges,
} = reducer.actions;
