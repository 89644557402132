import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppDispatch, useAppSelector } from 'src/store/reducers';
import { dismissKanIkkeLagre } from 'src/store/reducers/uiSlice';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

export const KanIkkeLagreModal = (): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const kanIkkeLagre = useAppSelector(state => state.refero.ui.kanIkkeLagre);
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const kanIkkeLagreErrorTitle = resources?.kanIkkeLagreErrorTitle || '';
  const kanIkkeLagreErrorBody = resources?.kanIkkeLagreErrorBody;
  const confirmationActionButton = resources?.confirmationActionButton;
  const onKanIkkeLagreClose = (): void => {
    dispatch(dismissKanIkkeLagre());
  };

  return kanIkkeLagre ? (
    <Modal
      variant="error"
      testId="kan-ikke-lagre-error"
      onClose={onKanIkkeLagreClose}
      title={kanIkkeLagreErrorTitle}
      description={kanIkkeLagreErrorBody}
      onSuccess={onKanIkkeLagreClose}
      primaryButtonText={confirmationActionButton}
    />
  ) : null;
};
export default KanIkkeLagreModal;
