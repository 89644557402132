import { Bundle, BundleEntry, Questionnaire, QuestionnaireResponse } from 'fhir/r4';
import { redirectTilInnsendtSkjemaUrl, redirectTilLagretSkjemaPaaDokumenterUrl } from 'src/constants/apiConsts';
import { SKJEMA_URL } from 'src/constants/constants';
import { GlobalState } from 'src/store/reducers';
import { CreateSkjemasvarRequest, Metadata, UpdateSkjemasvarRequest } from 'src/types/MinHelseEntities';
import { getQueryParams, vedleggMaFjernes } from 'src/util';

import LanguageLocales from '@helsenorge/core-utils/constants/languages';
import { HelsenorgeHttpError, HelsenorgeProxyError, ProxyErrorResponse } from '@helsenorge/framework-utils/hn-proxy-service';
import { HNeventSetDriftsmeldingPath } from '@helsenorge/framework-utils/web-component/events';
import {
  generateQuestionnaireResponse,
  getQuestionnaireItemsWithType,
  getResponseItemAndPathWithLinkId,
  ItemTypeConstants,
} from '@helsenorge/refero';

import { FetchError } from './types';

export const isNewDocument = (state: GlobalState): boolean => {
  return state.refero.ui.userMetadata?.IsNewDocument ?? false;
};
export function getFilteredParams(): string {
  const params: { [key: string]: string | undefined } = { ...getQueryParams() };
  delete params.Query;

  const stringParams = Object.keys(params).map(key => {
    const value = params[key];
    return `${key}=${value ?? ''}`;
  });

  return stringParams.join('&');
}

export const createQuestionnaireResponse = (state: GlobalState): QuestionnaireResponse => {
  return {
    status: 'in-progress',
    resourceType: 'QuestionnaireResponse',
    ...state.refero.form.FormData.Content,
    questionnaire: state.refero.form.FormDefinition.Content?.url,
  };
};
export function getQuestionnaireInLanguage(questionnaires: Array<Questionnaire>, language: LanguageLocales): Questionnaire | undefined {
  const skjemaDefinition = questionnaires.filter(q => q.language?.toLowerCase() === language?.toLowerCase());
  return skjemaDefinition.length > 0 ? skjemaDefinition[0] : undefined;
}

export function getLoadParameter(query: string, skjemaTekniskNavnFraUrl: string): [string, string] {
  if (skjemaTekniskNavnFraUrl) {
    return ['name', skjemaTekniskNavnFraUrl];
  }
  const nameMatchString = 'Questionnaire?name=';
  const idMatchString = 'Questionnaire/';
  const hasNameParam = decodeURIComponent(query).indexOf(nameMatchString) > -1;
  return hasNameParam
    ? ['name', decodeURIComponent(query).substr(nameMatchString.length)]
    : ['id', decodeURIComponent(query).substr(idMatchString.length)];
}

export const createSavePayload = (
  qr: string,
  state: GlobalState,
  automatiskLagring: boolean,
  withDiscretion?: boolean,
  isMicrowebStep?: boolean,
  dokumentGuidMicroweb?: string
): UpdateSkjemasvarRequest | CreateSkjemasvarRequest => {
  let dokumentGuid = state.refero.ui.dokumentGuid;
  const taskId = state.refero.ui.taskId;
  const skalMicrowebStepOverskriveEksisterendeDokument = isMicrowebStep && dokumentGuidMicroweb;

  if (skalMicrowebStepOverskriveEksisterendeDokument) {
    dokumentGuid = dokumentGuidMicroweb;
  }

  const payload = {
    ...(dokumentGuid && { DokumentGuid: dokumentGuid }),
    ...(taskId && { OppgaveGuid: taskId }),
    ...(withDiscretion && { Discretion: true }),
    AutomatiskLagring: automatiskLagring,
    QuestionnaireResponse: qr,
    ...(skalMicrowebStepOverskriveEksisterendeDokument && { LageNyttDokument: true }),
  };

  return payload;
};

export function getNewUrl(tekniskNavn: string): string {
  return `${SKJEMA_URL}/${tekniskNavn}`;
}

export const gotoUrl = (url: string): void => {
  window.location.assign(url);
};

export const redirectTilLagretSkjemaPaaDokumenter = (id?: string): void => {
  gotoUrl(redirectTilLagretSkjemaPaaDokumenterUrl(id));
};

export const redirectTilInnsendtSkjema = (id: string): void => {
  gotoUrl(redirectTilInnsendtSkjemaUrl(id));
};

export const gotoMinHelse = (): void => {
  gotoUrl('/');
};

export const getQuestionnairs = (parsedData: Questionnaire | Bundle | undefined): Questionnaire[] | undefined => {
  if (!parsedData) {
    return;
  }
  if (parsedData.resourceType === 'Bundle' && parsedData.total === 0) {
    return;
  }
  if (parsedData.resourceType === 'Bundle') {
    return parsedData.entry
      ?.map((entry: BundleEntry) => {
        return entry.resource;
      })
      .filter(x => x?.resourceType === 'Questionnaire');
  } else {
    return [parsedData];
  }
};

export const postUrl = (
  url: string,
  params: {
    [key: string]: string | undefined;
    contextParameter?: string;
    dokumentGuid?: string;
  }
): void => {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = url;

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key] || '';

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

export const updateUrlAndFetchMessage = (mainQuestionnaire: Questionnaire): void => {
  const filteredParams = getFilteredParams();
  const filteredParamsUrl = filteredParams ? `?${filteredParams}` : '';
  const newUrlPath = getNewUrl(mainQuestionnaire.name || '').replace(/\/+/g, '/');
  window.history.replaceState(window.history.state, '', `/${newUrlPath}${filteredParamsUrl}`);
  HNeventSetDriftsmeldingPath(encodeURIComponent(newUrlPath));
};
export const removeAttachmentsIfNeeded = (metadata: Metadata, qr: QuestionnaireResponse, mainQuestionnaire: Questionnaire): void => {
  if (vedleggMaFjernes(metadata)) {
    const itemsWithType = getQuestionnaireItemsWithType(ItemTypeConstants.ATTATCHMENT, mainQuestionnaire.item);

    itemsWithType?.forEach(itemWithType => {
      qr.item?.forEach(responseItem => {
        const itemsWithPath = getResponseItemAndPathWithLinkId(itemWithType.linkId, responseItem);
        itemsWithPath.forEach(ip => delete ip.item.answer);
      });
    });
  }
};
export const generateQuestionnaireResponseData = (
  questionnaireResponse: string | undefined,
  mainQuestionnaire: Questionnaire,
  isMicroweb: boolean | undefined,
  skjemadata: string | undefined
): QuestionnaireResponse => {
  if (isMicroweb && skjemadata !== undefined) {
    const parseOuterObject = JSON.parse(skjemadata);
    return parseOuterObject.Content as QuestionnaireResponse;
  }
  return questionnaireResponse ? JSON.parse(questionnaireResponse) : generateQuestionnaireResponse(mainQuestionnaire);
};

export function isFetchError(obj: unknown): obj is FetchError {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'message' in obj &&
    typeof (obj as FetchError).message === 'string' &&
    ('name' in obj ? typeof (obj as FetchError).name === 'string' : true) &&
    ('statusCode' in obj ? typeof (obj as FetchError).statusCode === 'number' : true) &&
    ('correlationId' in obj ? typeof (obj as FetchError).correlationId === 'string' || (obj as FetchError).correlationId === null : true) &&
    ('response' in obj ? typeof (obj as FetchError).response !== 'undefined' : true) &&
    ('type' in obj ? ['unknown', 'HelsenorgeProxyError', 'HelsenorgeHttpError', 'Error'].includes((obj as FetchError).type!) : true)
  );
}
export const isHelsenorgeProxyError = (error: FetchError): error is HelsenorgeProxyError => {
  return error.type === 'HelsenorgeProxyError';
};
export const isHelsenorgeHttpError = (error: FetchError): error is HelsenorgeHttpError => {
  return error.type === 'HelsenorgeHttpError';
};
export function isProxyErrorResponse(error: unknown): error is ProxyErrorResponse {
  if (typeof error === 'object' && error !== null && 'message' in error && 'code' in error && 'errorCategory' in error) {
    const e = error as { message: unknown; code: unknown; errorCategory: unknown };
    return typeof e.message === 'string' && typeof e.code === 'string' && typeof e.errorCategory === 'number';
  }
  return false;
}
