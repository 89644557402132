import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';

import { TopMessageProps } from './TopMessageComponent';
import { AuthenticationRequirement } from '../../constants/authenticationRequirement';
import { TopMessageVisibility } from '../../constants/topMessageVisibility';
import {
  brukerHarIkkeTilgangOgKanPrinteSkjema,
  brukerHarIkkeTilgangTilSkjema,
  hentSotHarIkkeTilgangFeilmelding,
  kanIkkeAutofylleskjema,
  vedleggMaFjernes,
} from '../../util';
import { hentOversettelse } from '../../util/stringToLanguageLocales';

type NotificaionPanalContent = { message: string; variant: 'info' | 'warn' | 'error'; testId?: string };

export const getNotificationPanelContent = (props: TopMessageProps): NotificaionPanalContent | null => {
  const { resources, authenticationRequirement, userMetadata, printReference, visibility } = props;

  if (!isAuthorized() && authenticationRequirement !== AuthenticationRequirement.Anonymous) {
    if (authenticationRequirement === AuthenticationRequirement.Optional) {
      return {
        message: resources.skjemaLoginMessageAuthenticationRequirementOptionalPrint,
        variant: 'info',
        testId: 'notification-panel-not-authorized',
      };
    }

    if (printReference) {
      if (visibility === TopMessageVisibility.Off) return null;
      return {
        message: resources.skjemaLoginMessageAuthenticationRequirementRequiredPrint,
        variant: 'info',
        testId: 'notification-panel-not-authorized',
      };
    }

    return {
      message: resources.skjemaLoginMessageAuthenticationRequirementRequiredNoPrint,
      variant: 'info',
      testId: 'notification-panel-not-authorized',
    };
  }

  if (isAuthorized()) {
    if (brukerHarIkkeTilgangOgKanPrinteSkjema(userMetadata, printReference) && visibility !== TopMessageVisibility.Off) {
      const message = `${hentSotHarIkkeTilgangFeilmelding(
        userMetadata.ForbudBegrunnelse,
        resources
      )} ${resources.message_printVersionAvailable}`;
      return {
        message,
        variant: 'info',
      };
    }

    if (brukerHarIkkeTilgangTilSkjema(userMetadata, printReference)) {
      const message = hentSotHarIkkeTilgangFeilmelding(userMetadata.ForbudBegrunnelse, resources);
      return {
        message,
        variant: 'error',
      };
    }

    if (vedleggMaFjernes(userMetadata)) {
      return {
        message: resources.message_skjemaHaddeVedlegg,
        variant: 'warn',
      };
    }

    if (kanIkkeAutofylleskjema(userMetadata)) {
      const message = hentOversettelse('kan_ikke_autofylle_skjema', resources);
      return {
        message,
        variant: 'warn',
      };
    }
  }

  return null;
};
