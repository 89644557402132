import React from 'react';

import { HNSkjemaFrontend } from 'src/types/Resources';

type Props = {
  resources?: HNSkjemaFrontend;
  opening: boolean;
};

const HelpButton = ({ resources, opening }: Props): React.JSX.Element => {
  return (
    <button
      data-testid="help-button"
      type="button"
      title={resources ? resources.helpButtonTooltip : undefined}
      className="atom_inline-functionbutton"
      aria-expanded={opening}
    />
  );
};
export default HelpButton;
