import LanguageLocales from '@helsenorge/core-utils/constants/languages';

export const SKJEMA_URL = 'skjemautfyller';
export const VEILEDER_URL = 'veileder';
export const LOGGETUT_FEILCODE = 'EHGW-130004';
export const FEIL = 'FEIL';
export const LANGUAGE_Code = 'lang';
export const DEFAULT_LANGUAGE = LanguageLocales.NORWEGIAN;
export const TQQC = 'http://ehelse.no/fhir/ValueSet/TQQC';

/**
 * - Represents default maximum file size (bytes) allowed for attachments for 'HN Skjema'
 * - This is the default vaule, note however that Refero can override this setting if
 * - Questionnaires own max validation rule is set.
 * @constant
 * @type {number}
 */
export const DEFAULT_MAX_ATTACHMENT_FILE_SIZE_HN_SKJEMA_BYTES = 4 * 1024 * 1024;
export const HN_SKJEMA_FRONTEND_RESOURCES = 'HN.Skjema.Frontend';
export const HN_SKJEMA_ERRORCODES_RESOURCES = 'HN.Skjema.ErrorCodes';
