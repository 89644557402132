import React from 'react';

import { HNSkjemaFrontend } from 'src/types/Resources';
import { getLoginUrl } from 'src/util';

import { AnchorHelpTrigger } from './AnchorHelpTrigger';

type Props = {
  word: string;
  openSidebar: () => void;
  resources: HNSkjemaFrontend;
};
export const ReplaceLink = ({ word, openSidebar, resources }: Props): React.JSX.Element | null => {
  if (word.indexOf('{omSkjemaLink}') > -1) {
    return <AnchorHelpTrigger openSidebar={openSidebar} />;
  } else if (word.indexOf('{loginLink}') > -1) {
    return (
      <React.Fragment>
        <a href={getLoginUrl()}>{word.replace('{loginLink}', resources.loggeInnLinkText)}</a>
        {` `}
      </React.Fragment>
    );
  } else if (word.indexOf('{personverninnstillingerLink}') > -1) {
    return (
      <React.Fragment>
        <a href="/personverninnstillinger">{word.replace('{personverninnstillingerLink}', resources.personverninnstillingerLink)}</a>
        {` `}
      </React.Fragment>
    );
  }
  return <React.Fragment>{`${word} `}</React.Fragment>;
};
