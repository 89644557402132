import { UnknownAction, combineReducers, configureStore, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import pendingChanges, { PendingChangesState } from '@helsenorge/core-framework/pending-changes/pending-changes-state';
import hjelpeskuff, { HjelpeskuffState } from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import { cmsTjenesteTekster, CmsTjenesteTeksterState } from '@helsenorge/framework-utils/cms-tjeneste-tekster';
import { ProxyErrorResponse } from '@helsenorge/framework-utils/hn-proxy-service';
import { resources, ResourcesState } from '@helsenorge/framework-utils/resources';
import { Form as ReferoFormState, form } from '@helsenorge/refero';

import ui, { UiState } from './uiSlice';

export type GlobalState = {
  resources: ResourcesState;
  hjelpeskuff: HjelpeskuffState;
  refero: {
    ui: UiState;
    form: ReferoFormState;
  };
  cmsTjenesteTekster: CmsTjenesteTeksterState;
  pendingChanges: PendingChangesState;
};

const rootReducer = combineReducers({
  resources,
  hjelpeskuff,
  refero: combineReducers({
    ui,
    form,
  }),
  cmsTjenesteTekster,
  pendingChanges,
});

export const store = configureStore({ reducer: rootReducer, middleware: getDefaultMiddleware => getDefaultMiddleware() });

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: string | ProxyErrorResponse;
  extra: { s: string; n: number };
}>();

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, UnknownAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export function useAppSelector<TSelected = unknown>(selector: (state: RootState) => TSelected): TSelected {
  return useSelector(selector);
}
export default rootReducer;
