import { getSkjemaResources } from 'src/components/renderUtils';
import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import { Resources } from '@helsenorge/refero';

export const useGetSchemaResources = (isMicrowebStep?: boolean): Resources | undefined => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const isSavedInDokumenter = useAppSelector(state => state.refero.ui.isSavedInDokumenter);
  const harEndepunkt = useAppSelector(state => state.refero.ui.harEndepunkt);
  const harGuidanceAction = useAppSelector(state => state.refero.ui.harGuidanceAction);

  if (!resources) return;
  return getSkjemaResources(resources, isMicrowebStep, isSavedInDokumenter, harEndepunkt, harGuidanceAction);
};
