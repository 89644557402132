import { useState } from 'react';

export const useLastActivity = (): {
  lastActivity: number;
  timeUserHasBeenIdle: number;
  setLastActivity: React.Dispatch<React.SetStateAction<number>>;
} => {
  const [lastActivity, setLastActivity] = useState(Date.now());

  return { lastActivity, timeUserHasBeenIdle: Date.now() - lastActivity, setLastActivity };
};
