import React from 'react';

import { HNSkjemaFrontend } from '../../types/Resources';

import designsystemtypography from '@helsenorge/designsystem-react/scss/typography.module.scss';

import { ReplaceLink } from './ReplaceLink';

type Props = {
  messageText: string;
  resources: HNSkjemaFrontend;
  openSidebar: () => void;
};

export const ReplaceLinks = ({ messageText, resources, openSidebar }: Props): React.JSX.Element => {
  return (
    <div className={`page_refero__topmessage  ${designsystemtypography['anchorlink-wrapper']}`}>
      {messageText
        ?.split(' ')
        ?.map((word, i) => <ReplaceLink key={word + i} word={word} openSidebar={openSidebar} resources={resources} />)}
    </div>
  );
};
