import { HelsenorgeHttpError, HelsenorgeProxyError } from '@helsenorge/framework-utils/hn-proxy-service';

export class UnknownErrorType {
  Error: string = '';
}
export class ProxyErrorResponse {
  Code: string = '';
  Message: string = '';
  ErrorCategory: number = 0;
}

export class TextMessage {
  Title: string = '';
  Body: string = '';
}
export const UNKNOWN_ERROR_CODE = 'UNKNOWN-ERROR-CODE';
export const transformFetchErrors = (
  error?: HelsenorgeProxyError | HelsenorgeHttpError | UnknownErrorType | unknown | TextMessage | ProxyErrorResponse
): ProxyErrorResponse | string => {
  let failure: ProxyErrorResponse = {
    Code: '',
    Message: '',
    ErrorCategory: 1,
  };
  if (error instanceof ProxyErrorResponse) {
    failure = error;
  } else {
    if (error instanceof HelsenorgeProxyError) {
      failure.Message = error.response.message;
      failure.Code = error.response.code;
      failure.ErrorCategory = error.response.errorCategory;
    }
    if (error instanceof HelsenorgeHttpError) {
      failure.Message = error.message;
      failure.Code = (error.response as { code: string })?.code || UNKNOWN_ERROR_CODE;
    }

    if (error instanceof UnknownErrorType) {
      failure.Message = error.Error;
      failure.Code = (error as { response?: { code?: string } })?.response?.code || UNKNOWN_ERROR_CODE;
    } else {
      failure.Message = 'Det har skjedd en teknisk feil. Prøv igjen senere.';
      failure.Code = (error as { response?: { code?: string } })?.response?.code || UNKNOWN_ERROR_CODE;
    }
  }
  return failure;
};
