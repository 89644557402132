import { FormDefinition, isItemSidebar } from '@helsenorge/refero';

export const shouldFormBeDisplayedAsStepView = (formDefinition: FormDefinition): boolean => {
  let shouldDisplay = false;
  formDefinition.Content?.item?.find(qItem =>
    qItem.extension?.find(extension =>
      extension.valueCodeableConcept?.coding?.find(coding => {
        if (coding.code === 'step') {
          shouldDisplay = true;
        }
      })
    )
  );
  return shouldDisplay;
};

export const getStepArrayLength = (formDefinition: FormDefinition): number | undefined => {
  const stepArray = formDefinition.Content?.item?.filter(qItem => !isItemSidebar(qItem));
  return stepArray ? stepArray?.length - 1 : undefined;
};
