import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useGetSkjemaErrorTitleAndBodyFromResources } from 'src/hooks/useGetSkjemaErrorTitle';
import { useAppDispatch, useAppSelector } from 'src/store/reducers';
import { dismissLagreError } from 'src/store/reducers/uiSlice';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

export const LagreSkjemaErrorModal = (): React.JSX.Element | null => {
  const dispatch = useAppDispatch();
  const lagreSkjemaError = useAppSelector(state => state.refero.ui.lagreSkjemaError);
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const confirmationActionButton = resources?.confirmationActionButton;
  const { errorBody, errorTitle } = useGetSkjemaErrorTitleAndBodyFromResources(lagreSkjemaError);
  const onLagreErrorClose = (): void => {
    dispatch(dismissLagreError());
  };
  return lagreSkjemaError ? (
    <Modal
      variant="error"
      testId="lagre-skjema-error"
      onClose={onLagreErrorClose}
      title={errorTitle}
      description={errorBody}
      onSuccess={onLagreErrorClose}
      primaryButtonText={confirmationActionButton}
    />
  ) : null;
};
export default LagreSkjemaErrorModal;
