import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

export type Props = {
  erKopiLagret: boolean;
  onOkButtonClick: () => void;
};

const ConfirmationLightbox = ({ erKopiLagret, onOkButtonClick }: Props): React.JSX.Element | null => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  const skjemaSendtInn = useAppSelector(state => state.refero.ui.skjemaSendtInn);
  const samtaleGuid = useAppSelector(state => state.refero.ui.samtaleGuid);
  return skjemaSendtInn && samtaleGuid && resources ? (
    <Modal
      testId="confirmation-lightbox"
      title={resources.sendtConfirmation}
      description={erKopiLagret ? resources.sendtConfirmationText : resources.sendtConfirmationDiscretion}
      onSuccess={onOkButtonClick}
      noCloseButton
    />
  ) : null;
};

export default ConfirmationLightbox;
