import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import Modal from '@helsenorge/designsystem-react/components/Modal';

export type Props = {
  closeCallback: () => void;
  sendCallback: (withDiscretion?: boolean) => void;
  isDiscretionLightboxVisible: boolean;
};

const DiscretionLightbox = ({ sendCallback, isDiscretionLightboxVisible }: Props): React.JSX.Element | null => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));

  return isDiscretionLightboxVisible && resources ? (
    <Modal
      testId="discretion-lightbox"
      title={resources.confirmDiscretionTitle}
      description={resources.confirmDiscretionText}
      primaryButtonText={resources.confirmDiscretionlagreAndSendButtonText}
      secondaryButtonText={resources.confirmDiscretionSendButtonText}
      onSuccess={(): void => sendCallback()}
      onClose={(): void => sendCallback(true)}
    />
  ) : null;
};

export default DiscretionLightbox;
