import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import { ResourceHelpItems } from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import HjelpetriggerComponent from '@helsenorge/core-framework/prosesshjelp/hjelpetrigger/hjelpetrigger-component';

type Props = {
  openSidebar: () => void;
};

export const AnchorHelpTrigger = ({ openSidebar }: Props): React.JSX.Element => {
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));
  return (
    <span id="anchor_title_helptrigger" className="atom_helptrigger-container" data-testid="anchor_title_helptrigger">
      <HjelpetriggerComponent
        resources={
          {
            helpTriggerLabel: resources?.hjelpeknapp_ariaLabel,
          } as ResourceHelpItems
        }
        stopPropagation
        onClick={openSidebar}
      />
    </span>
  );
};
