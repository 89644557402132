import { HNSkjemaFrontend } from 'src/types/Resources';
import { getQueryParams } from 'src/util';
import { hentOversettelse } from 'src/util/stringToLanguageLocales';

import { Resources } from '@helsenorge/refero';

const getActionButtonTitle = (
  resources: HNSkjemaFrontend,
  isSavedInDokumenter?: boolean,
  harEndepunkt?: boolean,
  harGuidanceAction?: boolean
): string => {
  if (getQueryParams().redirect || (!harEndepunkt && harGuidanceAction)) {
    return hentOversettelse('formProceed', resources);
  }
  return isSavedInDokumenter ? hentOversettelse('formFinish', resources) : hentOversettelse('formSend', resources);
};

export const getSkjemaResources = (
  resources: HNSkjemaFrontend,
  isMicrowebStep?: boolean,
  isSavedInDokumenter?: boolean,
  harEndepunkt?: boolean,
  harGuidanceAction?: boolean
): Resources => {
  return {
    ...resources,
    formSend: isMicrowebStep ? resources.nextStep : getActionButtonTitle(resources, isSavedInDokumenter, harEndepunkt, harGuidanceAction),
    formCancel: isMicrowebStep ? resources.previousStep : resources.formCancel,
    formRequired: resources.formRequired, // brukes ikke i helsenorge-skjema
  };
};
