import { AuthenticationRequirement } from 'src/constants/authenticationRequirement';
import { useAppSelector } from 'src/store/reducers';
import { getAuthenticationRequirementValue } from 'src/util/extension';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';

export const useHandleAuthenticationRequirment = (): {
  allowAnonymous: boolean;
  authenticationRequirement: AuthenticationRequirement | undefined;
  isAuthorized: () => boolean;
  allowAnonymousOrAuthorized: boolean;
} => {
  const questionnaire = useAppSelector(state => state.refero.form.FormDefinition.Content);
  const shouldAllowAnonymous = (authenticationRequirement: AuthenticationRequirement | undefined): boolean => {
    switch (authenticationRequirement) {
      case AuthenticationRequirement.Anonymous:
      case AuthenticationRequirement.Optional:
        return true;
      default:
        return false;
    }
  };

  const authenticationRequirement = getAuthenticationRequirementValue(questionnaire);
  const allowAnonymous = shouldAllowAnonymous(authenticationRequirement);
  return {
    allowAnonymous,
    authenticationRequirement,
    isAuthorized,
    allowAnonymousOrAuthorized: allowAnonymous || isAuthorized(),
  };
};
