// Navigator.tsx

import React, { useState } from 'react';

import { useNavigator } from 'src/hooks/useNavigator';

import Icon from '@helsenorge/designsystem-react/components/Icon';
import ChevronDown from '@helsenorge/designsystem-react/components/Icons/ChevronDown';
import ChevronUp from '@helsenorge/designsystem-react/components/Icons/ChevronUp';

import { NavigatorMenu } from './NavigatorMenu';
import { useSectionScroll } from './useSectionScroll';
import { NavigatorSection } from '../../util/getSections';

type Props = {
  lastActivity: number;
};

const Navigator = ({ lastActivity }: Props): React.JSX.Element | null => {
  const { sections, isNavigatorInitiated } = useNavigator(lastActivity);

  const [selectedSection, setSelectedSection] = useState<NavigatorSection | null>(sections[0] || null);
  const [isNavigatorMenuExpanded, setIsNavigatorMenuExpanded] = useState<boolean>(false);

  useSectionScroll({ sections, setSelectedSection });

  const toggleNavigatorExpand = (): void => {
    setIsNavigatorMenuExpanded(!isNavigatorMenuExpanded);
  };

  if (sections.length === 0 || !isNavigatorInitiated) {
    return null;
  }

  return (
    <div className="page_refero__navigator container">
      <button
        id="navigator-button"
        className="page_refero__navigatorheader"
        onClick={toggleNavigatorExpand}
        aria-expanded={isNavigatorMenuExpanded}
      >
        <div className="page_refero__navigatorheader-text">{selectedSection?.name}</div>
        {isNavigatorMenuExpanded ? <Icon svgIcon={ChevronUp} /> : <Icon svgIcon={ChevronDown} />}
      </button>
      {isNavigatorMenuExpanded && (
        <NavigatorMenu
          sections={sections}
          setSelectedSection={setSelectedSection}
          toggleNavigatorExpand={toggleNavigatorExpand}
          selectedSection={selectedSection}
        />
      )}
    </div>
  );
};

export default Navigator;
