import React from 'react';

import { useAppSelector } from 'src/store/reducers';

import Loader from '@helsenorge/designsystem-react/components/Loader';

type Props = {
  children?: React.ReactNode;
};

export const SkjemaSpinners = ({ children }: Props): React.JSX.Element | React.ReactNode => {
  const showLocalSpinner = useAppSelector(state => state.refero.ui.showLocalSpinner);
  const showGlobalSpinner = useAppSelector(state => state.refero.ui.showGlobalSpinner);

  return showGlobalSpinner ? (
    <Loader size="large" overlay={'screen'} color="black" />
  ) : showLocalSpinner ? (
    <div className="page_refero__spinnerspacing">
      <Loader size={'large'} color="black" />{' '}
    </div>
  ) : (
    children
  );
};
