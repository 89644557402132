import React from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';

import { getLoginUrl } from '../util';

interface SkjemaLoginButtonProps {
  buttonText: string;
  doNotRender?: boolean;
}

const SkjemaLoginButton = ({ buttonText, doNotRender }: SkjemaLoginButtonProps): React.JSX.Element => {
  if (doNotRender) {
    return <></>;
  }
  return (
    <Button
      onClick={(): void => {
        window.history.replaceState(window.history.state, '', getLoginUrl());
        window.location.reload();
      }}
    >
      {buttonText}
    </Button>
  );
};

export default SkjemaLoginButton;
