import React from 'react';

import { HN_SKJEMA_FRONTEND_RESOURCES } from 'src/constants/constants';
import { useAppSelector } from 'src/store/reducers';
import { getResourcesSelector } from 'src/store/selectors/resourcesSelectors';
import { HNSkjemaFrontend } from 'src/types/Resources';

import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';

import { sanitize } from '@helsenorge/framework-utils/trusted-types';

export const LoadSkjemaWarningMessageNotification = (): React.JSX.Element | null => {
  const loadSkjemaoppgaveWarningMessage = useAppSelector(state => state.refero.ui.loadSkjemaoppgaveWarningMessage);
  const resources = useAppSelector<HNSkjemaFrontend>(state => getResourcesSelector(state, HN_SKJEMA_FRONTEND_RESOURCES));

  return loadSkjemaoppgaveWarningMessage ? (
    <section className="defaultspacing">
      <NotificationPanel
        testId="notification-panel-load-skjemaoppgave-warning-message"
        variant="warn"
        label={sanitize(
          resources?.[loadSkjemaoppgaveWarningMessage.Title as keyof HNSkjemaFrontend]
            ? resources[loadSkjemaoppgaveWarningMessage.Title as keyof HNSkjemaFrontend]
            : loadSkjemaoppgaveWarningMessage.Title
        )}
      >
        {resources?.[loadSkjemaoppgaveWarningMessage.Body as keyof HNSkjemaFrontend]
          ? resources?.[loadSkjemaoppgaveWarningMessage.Body as keyof HNSkjemaFrontend]
          : loadSkjemaoppgaveWarningMessage.Body}
      </NotificationPanel>
    </section>
  ) : null;
};
export default LoadSkjemaWarningMessageNotification;
