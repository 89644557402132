import React from 'react';

import { SidebarVisibility } from 'src/constants/sidebarVisibility';
import { HNSkjemaFrontend } from 'src/types/Resources';
import { hentOversettelse } from 'src/util/stringToLanguageLocales';

import Title, { TitleAppearances } from '@helsenorge/designsystem-react/components/Title';

import { ResourceHelpItems } from '@helsenorge/core-framework/prosesshjelp/hjelpeskuff/hjelpeskuff-state';
import HjelpetriggerComponent from '@helsenorge/core-framework/prosesshjelp/hjelpetrigger/hjelpetrigger-component';
import { FormDefinition } from '@helsenorge/refero';

const SkjemaTitle = ({
  formDefinition,
  resources,
  sidebarVisibility,
  isMicroweb,
  openSidebar,
}: {
  isMicroweb?: boolean;
  sidebarVisibility?: number;
  resources?: HNSkjemaFrontend;
  formDefinition?: FormDefinition | null;
  openSidebar: () => void;
}): React.JSX.Element | null => {
  if (!resources || !formDefinition || !formDefinition.Content || !formDefinition.Content.title) {
    return null;
  }
  const appearance: TitleAppearances = isMicroweb ? 'title2' : 'title1';

  return (
    <div className="page_refero__formtitlewrapper">
      <Title htmlMarkup="h1" appearance={appearance} className="page_refero__formtitle" id="sidetittel">
        {formDefinition.Content.title}
      </Title>
      {sidebarVisibility === SidebarVisibility.On && !isMicroweb && (
        <span id="anchor_title_helptrigger" className="atom_helptrigger-container" data-testid="anchor_title_helptrigger">
          <HjelpetriggerComponent
            resources={
              {
                helpTriggerLabel: hentOversettelse('hjelpeknapp_ariaLabel', resources),
              } as ResourceHelpItems
            }
            stopPropagation
            onClick={openSidebar}
          />
        </span>
      )}
    </div>
  );
};
export default SkjemaTitle;
