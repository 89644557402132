import React from 'react';

import classNames from 'classnames';

import { HNSkjemaFrontend } from '../../types/Resources';

import Button from '@helsenorge/designsystem-react/components/Button';
import { Icon } from '@helsenorge/designsystem-react/components/Icon';
import ChevronLeft from '@helsenorge/designsystem-react/components/Icons/ChevronLeft';
import HelpSign from '@helsenorge/designsystem-react/components/Icons/HelpSign';
import X from '@helsenorge/designsystem-react/components/Icons/X';

import aria, { Siblings } from '@helsenorge/core-utils/aria-hidden';
import layoutChange, { OriginalProps as LayoutChangeProps } from '@helsenorge/core-utils/hoc/layout-change';
import TrapFocus from '@helsenorge/core-utils/trapfocus';
import { theme } from '@helsenorge/designsystem-react';
import SafeHTML from '@helsenorge/framework-utils/components/safe-html';
import { link as proxyLink } from '@helsenorge/framework-utils/hn-proxy-service';
import { setAllowNavigation } from '@helsenorge/framework-utils/hn-user';
import { SidebarItem } from '@helsenorge/refero';

import Seksjon, { Props as SeksjonProps } from './seksjon';

interface SidebarState {
  disableTransition?: boolean;
}

export interface SidebarProps {
  resources: HNSkjemaFrontend;
  isOpen?: boolean;
  toggleShowSidebar: () => void;
  hideSidebar: () => void;
  showSidebar: () => void;
  kanLagres: boolean;
  kanSendes: boolean;
  harRepresentasjonsMulighet: boolean;
  sidebarData: Array<SidebarItem>;
  formTitle: string;
  printReference?: string;
}

class Sidebar extends React.Component<SidebarProps & LayoutChangeProps, SidebarState> {
  helpTitleRef: React.RefObject<HTMLHeadingElement>;

  private prevAriaHiddens: Siblings | undefined;
  private trapFocus: TrapFocus | undefined;
  private prevFocus: HTMLElement | undefined;
  private sidebarRef: React.RefObject<HTMLDivElement>;
  constructor(props: SidebarProps) {
    super(props);
    this.sidebarRef = React.createRef();
    this.helpTitleRef = React.createRef();

    this.state = { disableTransition: false };
    this.handleToggleClick = this.handleToggleClick.bind(this);
    // Adds event listener for when the user presses the Back-button in browser
    this.popStateListener = this.popStateListener.bind(this);
    this.clickListener = this.clickListener.bind(this);
    this.disableTransitionToPreventDoubleAnimation = this.disableTransitionToPreventDoubleAnimation.bind(this);
  }

  componentDidMount(): void {
    this.toggleFocus();
    window.addEventListener('popstate', this.popStateListener);
    window.addEventListener('click', this.clickListener);
  }

  componentDidUpdate(prevProps: SidebarProps & LayoutChangeProps): void {
    if (this.props.isOpen && prevProps.isOpen !== this.props.isOpen) {
      this.toggleFocus();
    }
    if (prevProps.isOpen && prevProps.isOpen !== this.props.isOpen) {
      this.releaseFocus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: SidebarProps & LayoutChangeProps): void {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.open();
    } else if (this.props.isOpen && !nextProps.isOpen) {
      this.close();
    }
  }

  componentWillUnmount(): void {
    this.releaseFocus();
    window.removeEventListener('popstate', this.popStateListener);
    window.removeEventListener('click', this.clickListener);
  }

  generateSectionContent(header: string, content: Array<string>): SeksjonProps {
    return {
      header: header,
      content: content.map((x: string, index: number) => <SafeHTML key={index} html={x} tagName="p"></SafeHTML>),
    };
  }

  wrapSidebarTekst(sidebarTekst: string): string {
    return `${sidebarTekst}\n\n`;
  }

  generateSkjemaSidebarSections(): Array<SeksjonProps> {
    const { resources, sidebarData } = this.props;
    const seksjonerFraSkjema: { [key: string]: Array<string> } = {
      'SOT-1': [],
      'SOT-2': [],
      'SOT-3': [],
    };

    sidebarData.forEach((x: SidebarItem) => {
      if (x.item.code && x.item.code.length > 0 && x.item.code[0].code) {
        if (seksjonerFraSkjema[x.item.code[0].code]) {
          seksjonerFraSkjema[x.item.code[0].code].push(x.markdownText);
        }
      }
    });

    if (this.props.harRepresentasjonsMulighet) {
      seksjonerFraSkjema['SOT-1'].push(this.wrapSidebarTekst(resources.sidebarSectionText_Alternativer_Representasjon));
    }
    seksjonerFraSkjema['SOT-2'].push(this.wrapSidebarTekst(resources.sidebarSectionText_Veiledning_KontaktHelsenorge));
    if (this.props.kanLagres) {
      seksjonerFraSkjema['SOT-2'].push(
        this.wrapSidebarTekst(
          this.props.kanSendes
            ? resources.sidebarSectionText_Veiledning_LagringInnboks
            : resources.sidebarSectionText_Veiledning_LagringDokument
        )
      );
    } else {
      seksjonerFraSkjema['SOT-2'].push(this.wrapSidebarTekst(resources.sidebarSectionText_Veileder_Opplysninger_KontaktHelsenorge));
    }

    if (this.props.printReference) {
      const printName = `${this.props.resources.sidebar_printLink} ${this.props.formTitle}`;
      const printUrl = proxyLink('skjemainternal', 'api/v1/Binary', {
        id: this.props.printReference.split('/')[1],
      });
      seksjonerFraSkjema['SOT-1'].push(
        this.wrapSidebarTekst(resources.sidebarSectionText_Alternativer_LoggInn.replace('{0}', `<a href="${printUrl}">${printName}</a>`))
      );
    }

    return [
      this.generateSectionContent(resources.sidebarSectionHeader_Alternativer, seksjonerFraSkjema['SOT-1']),
      this.generateSectionContent(resources.sidebarSectionHeader_Veiledning, seksjonerFraSkjema['SOT-2']),
      this.generateSectionContent(resources.sidebarSectionHeader_BehandlingMottaker, seksjonerFraSkjema['SOT-3']),
    ];
  }

  toggleFocus(): void {
    if (this.props.isOpen) {
      this.setTrapFocus();
      // eslint-disable-next-line react/no-find-dom-node
      const node: Element | Text | null = this.sidebarRef.current;
      if (node instanceof HTMLDivElement) {
        this.prevAriaHiddens = aria.setAriaHidden(node);
        if (this.helpTitleRef.current) {
          this.helpTitleRef.current.focus();
        }
      }
    }
  }

  setTrapFocus(): void {
    if (this.trapFocus) {
      return; // Focus already trapped
    }
    this.prevFocus = document.activeElement as HTMLElement;
    // eslint-disable-next-line react/no-find-dom-node
    this.trapFocus = new TrapFocus(this.sidebarRef.current as HTMLElement);
  }

  releaseFocus(): void {
    if (this.prevAriaHiddens) {
      aria.resetAriaHidden(this.prevAriaHiddens);
    }
    if (this.trapFocus) {
      this.trapFocus.deactivate();
      this.trapFocus = undefined;
      if (this.prevFocus) {
        this.prevFocus.focus();
      }
      this.prevFocus = undefined;
    }
  }

  handleToggleClick(): void {
    this.props.toggleShowSidebar();
  }

  elementIsInSidebarLink(eventTarget: EventTarget[]): boolean {
    for (const target of eventTarget) {
      if ((target as HTMLElement)?.classList?.contains('sidebar-link')) {
        return true;
      }
    }
    return false;
  }

  elementIsInHelpDrawer(eventTarget: EventTarget[]): boolean {
    for (const target of eventTarget) {
      if ((target as HTMLElement)?.classList?.contains('skjemasidebar')) {
        return true;
      }
    }
    return false;
  }

  isMobile = (): boolean => {
    return !!(this.props.nullToXs || this.props.smToMd);
  };

  open(): void {
    // Only push state to enable back/forward browser navigation if help drawer use the full width (mobile)
    if (this.isMobile() && !this.isOpenInHistoryState()) {
      window.history.pushState(Object.assign({}, window.history.state, { helpDrawerOpen: true }), '', window.location.href);
    }
    this.setScroll();
  }

  close(): void {
    if (this.isMobile() && this.isOpenInHistoryState()) {
      setAllowNavigation(true);
      window.history.back();
    }
    if (!this.isLightboxOpen()) {
      this.removePreventscroll();
    }
  }

  isOpenInHistoryState(): boolean {
    return window.history.state && window.history.state.helpDrawerOpen;
  }

  setScroll(): void {
    if (document.body.classList.contains('preventscroll') && !this.props.isOpen && !this.isLightboxOpen()) {
      this.removePreventscroll();
    } else {
      document.body.classList.add('preventscroll');
    }
  }

  removePreventscroll(): void {
    document.body.classList.remove('preventscroll');
  }

  isLightboxOpen(): boolean {
    return document.querySelectorAll('.toolkit-lightbox').length > 0;
  }

  popStateListener(): void {
    setAllowNavigation(false);
    const helpDrawerOpen: boolean = window.history.state && window.history.state.helpDrawerOpen;
    if (helpDrawerOpen && !this.props.isOpen) {
      this.disableTransitionToPreventDoubleAnimation(this.props.showSidebar);
    } else if (!helpDrawerOpen && this.props.isOpen) {
      this.disableTransitionToPreventDoubleAnimation(this.props.hideSidebar);
    }
  }

  disableTransitionToPreventDoubleAnimation(callback: () => void): void {
    this.setState({ disableTransition: true }, callback);
    window.setTimeout(() => {
      this.setState({ disableTransition: false });
    }, 500);
  }

  clickListener(e: MouseEvent): void {
    if (this.props.isOpen) {
      const eventPath = e.composedPath();
      if (!this.elementIsInHelpDrawer(eventPath) && !this.elementIsInSidebarLink(eventPath)) {
        this.props.hideSidebar();
      }
    }
  }

  render(): React.JSX.Element | null {
    const { resources }: SidebarProps = this.props;

    const helpDrawerClasses: string = classNames({
      skjemasidebar: true,
      open: this.props.isOpen,
    });

    const style: React.CSSProperties = this.state.disableTransition ? { transitionDuration: '0s' } : {};
    const seksjonsData = this.generateSkjemaSidebarSections();

    return (
      <div className={helpDrawerClasses} style={style} data-testid="sidebar" ref={this.sidebarRef}>
        <span className="skjemasidebar__control">
          <button
            data-testid="sidebar-toggle"
            className="skjemasidebar__controlbutton"
            type="button"
            onClick={this.handleToggleClick}
            tabIndex={0}
            aria-label={this.props.isOpen ? resources.sidebar_closelabel : resources.sidebar_openlabel}
          >
            <Icon className="icon_question_sidebar" svgIcon={HelpSign} color={theme.palette.white} />
            <Icon className="icon_arrow_sidebar" svgIcon={ChevronLeft} color={theme.palette.white} />
          </button>
        </span>
        <div className="skjemasidebar__contents" aria-hidden={!this.props.isOpen}>
          <div className="skjemasidebar__drawerheader">
            <div
              className="skjemasidebar__title-wrap"
              role="heading"
              aria-level={1}
              aria-label={`${resources.sidebar_titlearia} ${this.props.resources.sidebar_titleSkjema}`}
              ref={this.helpTitleRef}
            >
              <Icon svgIcon={HelpSign} color={theme.palette.white} />
              <h1 className="skjemasidebar__title">{this.props.resources.sidebar_titleSkjema}</h1>
            </div>
            <Button testId="hide-sidebar" onClick={this.props.hideSidebar} variant={'borderless'} ariaLabel={resources.sidebar_closelabel}>
              <Icon svgIcon={X} color={theme.palette.white} />
            </Button>
          </div>
          <div>
            {seksjonsData
              .filter(x => x.content.length > 0)
              .map(x => {
                return <Seksjon key={x.header} header={x.header} content={x.content} />;
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default layoutChange(Sidebar);
